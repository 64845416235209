import React, { useContext, useState } from 'react'

import { request } from '../../../helpers/request'
import { Discount } from '../../../types/discount'
import { SMIconButton, SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMTextField } from '../../../SMComponents/SMInput'
import { Checkbox } from '@mui/material'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { AppContext } from '../../../contexts/AppContext'

export const StarOptions = ({ discount }: { discount: Discount }) => {
  const { handleOpenSnackBar } = useContext(AppContext)

  const [isLoadingAiFields, setIsLoadingAiFields] = useState(false)
  const [isLoadingGMapsFields, setIsLoadingGMapsFields] = useState(false)
  const [isLoadingHide, setIsLoadingHide] = useState(false)
  const [isEditingReservation, setIsEditingReservation] = useState(false)
  const [isReservableInput, setIsReservableInput] = useState(discount.isReservable)
  const [reservationLinkInput, setReservationLinkInput] = useState(discount.reservationLink)

  const handleClickOnSave = async () => {
    const response = await request('update_discount_reservation', {
      method: 'POST',
      body: JSON.stringify({
        discountId: discount.id,
        isReservable: isReservableInput,
        reservationLink: reservationLinkInput,
      }),
    })
    console.log(response?.data || 'no data')
    handleOpenSnackBar({
      message: response?.message || 'Error',
    })
  }

  return (
    <div>
      <p>Local: {discount.local}</p>
      <p
        style={
          discount.mainTag?.includes('rest')
            ? { color: 'red', fontWeight: 'bold' }
            : { fontWeight: 'bold' }
        }
      >
        MainTagV2: {discount.mainTagV2}
      </p>
      <p>Regiones: {discount.regiones?.length == 16 ? 'TODAS' : discount.regiones?.join('. ')}</p>
      <SMHorizontalDiv>
        <SMVerticalDiv>
          {isEditingReservation ? (
            <>
              <SMHorizontalDiv>
                <p>Es reservable</p>
                <Checkbox
                  disabled={
                    !discount.mainTag?.includes('restaurantes') ||
                    !discount.tipoConsumo?.includes('presencial')
                  }
                  checked={!!isReservableInput}
                  onClick={(e: any) => {
                    setIsReservableInput(!isReservableInput)
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                />
              </SMHorizontalDiv>
              <SMTextField
                value={reservationLinkInput || ''}
                placeholder="Link de reserva"
                sx={{ backgroundColor: 'white', width: 300 }}
                onChange={(e: any) => setReservationLinkInput(e.target.value)}
                onClick={(e: any) => e.stopPropagation()}
              />
            </>
          ) : (
            <>
              <p>Es reservable: {isReservableInput ? 'Si' : 'No'}</p>
              {isReservableInput ? <p>Link: {reservationLinkInput}</p> : null}
            </>
          )}
        </SMVerticalDiv>
        <SMIconButton
          onClick={() => {
            const wantsToSaveChanges = isEditingReservation
            setIsEditingReservation(!isEditingReservation)
            if (wantsToSaveChanges) {
              handleClickOnSave()
            }
          }}
        >
          {isEditingReservation ? <SaveRoundedIcon /> : '✎'}
        </SMIconButton>
      </SMHorizontalDiv>
      {}
      {discount.mainTag?.includes('rest') ? <p>tipoConsumo: {discount.tipoConsumo}</p> : null}
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingAiFields}
        color="secondary"
        sx={{ margin: '8px 0px' }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingAiFields(true)
          request(`re_do_discount_ai_fields/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingAiFields(false)
          })
        }}
      >
        ↺ AI
      </SMPrimaryButton>
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingGMapsFields}
        color="primary"
        sx={{ margin: '2px 2px' }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingGMapsFields(true)
          request(`update_one_discount_gmaps/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingGMapsFields(false)
          })
        }}
      >
        ↺ GMaps
      </SMPrimaryButton>
      <SMPrimaryButton
        sx={{ backgroundColor: 'green' }}
        href={
          'https://console.firebase.google.com/u/0/project/star-15bb5/firestore/databases/-default-/data/~2Fdiscounts~2F' +
          discount?.id
        }
      >
        BD
      </SMPrimaryButton>
      <SMPrimaryButton
        variant="contained"
        loading={isLoadingHide}
        sx={{ margin: '2px 2px', backgroundColor: 'black' }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsLoadingHide(true)
          request(`hide/${discount.id}`).then((response) => {
            console.log(response?.data || 'no data')
            setIsLoadingHide(false)
          })
        }}
      >
        ⌦
      </SMPrimaryButton>
    </div>
  )
}
