export enum RegionesEnum {
  METROPOLITANA = 'METROPOLITANA',
  ANTOFAGASTA = 'ANTOFAGASTA',
  ARAUCANIA = 'ARAUCANIA',
  ARICA = 'ARICA',
  ATACAMA = 'ATACAMA',
  AYSEN = 'AYSEN',
  BIOBIO = 'BIOBIO',
  COQUIMBO = 'COQUIMBO',
  LAGOS = 'LAGOS',
  LIBERTADOR = 'LIBERTADOR',
  MAGALLANES = 'MAGALLANES',
  MAULE = 'MAULE',
  NUBLE = 'NUBLE',
  RIOS = 'RIOS',
  TARAPACA = 'TARAPACA',
  VALPARAISO = 'VALPARAISO',
}
