import React, { Component } from 'react'
import NoResultsIlustration from '../src/assets/ilustrations/noResultsIlustration.svg'
import { secondarySecondary } from './style'

const style = {
  screen: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    alignItems: 'center',
    height: '100%',
  },
  image: {
    width: '80%',
    maxWidth: '180px',
  },
  title: {
    fontSize: '1.4rem',
    margin: '20px',
    textAlign: 'center',
  },
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error encontrado!:', error, errorInfo)
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={style.screen}>
          <img src={NoResultsIlustration} style={style.image} alt="No results" />
          <p style={style.title}>Lo Sentimo, hubo un error.</p>
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <p>Porfavor sacar pantallazo y mandarlo a nuestro instagram aca.</p>
            <a href="https://www.instagram.com/savemoney.cl/">Link a nuestro Instagram</a>
          </div>
          <div style={{ textAlign: 'center', fontSize: '12px', color: secondarySecondary }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
