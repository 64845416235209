import {
  MAP_DISCOUNT_CARD_HEIGHT_BIG,
  MAP_DISCOUNT_CARD_HEIGHT_SMALL,
} from '../../../../consts/stylesConsts'
import { googleMapsStarColor, neutral50White } from '../../../../style'
import googleMapsIcon from '../../../../assets/googleMapsIcon.svg'
import whatsappIcon from '../../../../assets/whatsappIcon.svg'

import { DiscountMapButton } from '../../../common/SMButton'
import style from './discountMapCard.module.css'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded'
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import {
  addUtmParamsToUrl,
  generateDiscountTitle,
  normalizeDaysToText,
} from '../../../../helpers/others'
import { request } from '../../../../helpers/request'
import { useContext, useEffect, useRef } from 'react'
import { AppContext } from '../../../../contexts/AppContext'

const {
  mapScrollableArea,
  headerClubBorder,
  headerClub,
  mapDiscountCard,
  mapDiscountHeaderFrame,
  mapHeaderImage,
  mapDiscountBodyFrame,
  mapCloseCardButton,
  mapDiscountScrollableButtonFrame,
  mapDiscountButtonText,
} = style

// const isProd = process.env.NODE_ENV === 'production'

const transformPriceLevelToPricesIcons = (priceLevel) => {
  if (priceLevel === 'PRICE_LEVEL_INEXPENSIVE') return <p>$</p>
  if (priceLevel === 'PRICE_LEVEL_MODERATE') return <p>$$</p>
  if (priceLevel === 'PRICE_LEVEL_EXPENSIVE') return <p>$$$</p>
  if (priceLevel === 'PRICE_LEVEL_VERY_EXPENSIVE') return <p>$$$$</p>
  return ''
}

const StarButtons = ({
  star,
  selected,
  approvedGMapsResults,
  removedGMapsResults,
  setApprovedGMapsResults,
  setRemovedGMapsResults,
}) => {
  const deleteButtonRef = useRef(null)
  const approveButtonRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'b') {
        deleteButtonRef?.current?.focus()
      }
      if (e.key === 'a') {
        approveButtonRef?.current?.focus()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  if (!selected?.gMapResult) return null
  // if (isProd && !star) return null
  if (!star) return null
  const { place_id, queryID } = selected.gMapResult
  if (approvedGMapsResults.includes(place_id) || removedGMapsResults.includes(place_id)) {
    return null
  }
  return (
    <>
      <DiscountMapButton
        buttonname={'delete_gmaps_result'}
        reference={deleteButtonRef}
        sx={{ backgroundColor: 'red', color: 'white' }}
        onClick={(e) => {
          e.stopPropagation()
          setRemovedGMapsResults([...removedGMapsResults, place_id])
          request('delete_gmaps_result', {
            method: 'POST',
            body: JSON.stringify({
              queryID,
              place_id,
            }),
          }).then((response) => {
            console.log(response?.status === 'success' ? 'success' : 'error')
          })
        }}
      >
        <p className={mapDiscountButtonText}>⚠️ BORRAR</p>
      </DiscountMapButton>
      <DiscountMapButton
        buttonname={'approve_gmaps_result'}
        reference={approveButtonRef}
        sx={{ backgroundColor: 'green', color: 'white' }}
        onClick={(e) => {
          e.stopPropagation()
          setApprovedGMapsResults([...approvedGMapsResults, place_id])
          console.log('approve_gmaps_result', queryID, place_id)
          request('approve_gmaps_result', {
            method: 'POST',
            body: JSON.stringify({
              queryID,
              place_id,
            }),
          }).then((response) => {
            console.log(response?.status === 'success' ? 'success' : 'error')
          })
        }}
      >
        <p className={mapDiscountButtonText}>😁 Aprobar</p>
      </DiscountMapButton>
    </>
  )
}

export const Rating = ({ mapsResult }) => {
  if (!mapsResult || !mapsResult.rating) return null
  const rating = mapsResult.rating
  const ratingInt = Math.floor(rating) // this is the integer part
  const ratingDec = rating - ratingInt // this is the decimal part
  const user_ratings_total = mapsResult.user_ratings_total || mapsResult.userRatingCount // GMapsResultsV2
  const stars = []
  const starSize = '16px'
  for (let i = 0; i < ratingInt; i++) {
    stars.push(
      <StarRoundedIcon key={i} style={{ color: googleMapsStarColor, fontSize: starSize }} />
    )
  }
  if (ratingDec > 0) {
    // full half has color: googleMapsStarColor, rest of the half is grey
    stars.push(
      <StarHalfRoundedIcon
        key={ratingInt}
        style={{ color: googleMapsStarColor, fontSize: starSize }}
      />
    )
  }
  for (let i = 0; i < 5 - ratingInt - (ratingDec > 0 ? 1 : 0); i++) {
    stars.push(
      <StarOutlineRoundedIcon
        key={10 + ratingInt + i}
        style={{ color: googleMapsStarColor, fontSize: starSize }}
      />
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '14px',
        color: neutral50White,
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid black',
        width: 'fit-content',
      }}
    >
      <p>{rating}</p>
      &nbsp;
      {stars}
      <p>&nbsp;({user_ratings_total})&nbsp; ·&nbsp;&nbsp;</p>
      {mapsResult?.priceLevel && transformPriceLevelToPricesIcons(mapsResult.priceLevel)}
    </div>
  )
}

export function DiscountMapCard({
  star,
  selected,
  setSelected,
  focusMap,
  setFocusMap,
  approvedGMapsResults,
  removedGMapsResults,
  setApprovedGMapsResults,
  setRemovedGMapsResults,
}) {
  const { clubs } = useContext(AppContext)
  const discount = selected?.discount
  const mapsResult = selected?.gMapResult
  if (!discount || !mapsResult) return null

  const whatsappMessage = `*${discount.titulo}*.\n
💳 ${discount.club} - ${discount.cantidadDescuento}% dcto.\n
📆 Disponible: ${normalizeDaysToText(discount.diasNormalizados)}
📍${selected.gMapResult.formatted_address}
🔗 Sitio oficial del dcto: ${discount.url}\n\n🚀SaveMoney🚀\nwww.savemoney.cl`
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`

  const cardBody = (
    <div className={mapDiscountBodyFrame}>
      <div className={headerClubBorder}>
        <div className={headerClub}>
          <img
            src={clubs[discount.club].img}
            style={{ width: clubs[discount.club].width }}
            alt="."
          />
        </div>
      </div>
      <div>
        <p className="body-1" style={{ fontSize: '17px' }}>
          {generateDiscountTitle(discount)}
        </p>
        {star && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="body-1" style={{ color: neutral50White }}>
              {mapsResult?.name || mapsResult?.displayName.text}
            </p>
            <p className="body-3" style={{ color: neutral50White }}>
              &nbsp;&nbsp;&nbsp;
              {mapsResult?.query}
            </p>
          </div>
        )}
        <Rating mapsResult={mapsResult} />
        {star ? (
          <>
            <p className="body-1" style={{ color: neutral50White }}>
              {mapsResult?.types?.join(', ')}
            </p>
          </>
        ) : (
          <>
            <p className="body-1" style={{ fontSize: '16px' }}>
              {discount?.club}
            </p>

            <p className="body-1" style={{ color: neutral50White }}>
              {normalizeDaysToText(discount.diasNormalizados)}
              &nbsp;&nbsp;-&nbsp;&nbsp;{discount?.fecha_hasta}
            </p>
          </>
        )}
      </div>
      <div className={mapDiscountScrollableButtonFrame}>
        <StarButtons
          star={star}
          selected={selected}
          approvedGMapsResults={approvedGMapsResults}
          removedGMapsResults={removedGMapsResults}
          setApprovedGMapsResults={setApprovedGMapsResults}
          setRemovedGMapsResults={setRemovedGMapsResults}
        />
        <DiscountMapButton
          buttonname={'maps_go_to_discount_button'}
          url={addUtmParamsToUrl(discount)}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <img
            src={clubs[discount.club]?.img}
            style={{ width: clubs[discount.club]?.width - 8 }}
            alt="."
          />
          <p className={mapDiscountButtonText}>Ir al sitio</p>
        </DiscountMapButton>
        <DiscountMapButton
          buttonname={'maps_share_button'}
          url={whatsappUrl}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <img
            src={whatsappIcon}
            alt="whatsappIcon"
            style={{
              height: '26px',
            }}
          />
          <p className={mapDiscountButtonText}>Compartir</p>
        </DiscountMapButton>
        <DiscountMapButton
          buttonname={'maps_go_to_maps_button'}
          url={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${mapsResult?.place_id}`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <img
            src={googleMapsIcon}
            alt="gmapsIcon"
            style={{
              height: '20px',
            }}
          />
          <p className={mapDiscountButtonText}>Abrir maps</p>
        </DiscountMapButton>
      </div>
    </div>
  )
  return (
    <div
      className={mapScrollableArea}
      style={{
        // transition: 'transform 0.3s ease-in-out',
        // transform,
        height: focusMap ? MAP_DISCOUNT_CARD_HEIGHT_SMALL : MAP_DISCOUNT_CARD_HEIGHT_BIG,
        transition: 'height 0.5s ease-in-out',
      }}
      onClick={() => {
        setFocusMap(!focusMap)
      }}
    >
      <div className={mapDiscountCard}>
        <div className={mapDiscountHeaderFrame}>
          <div className={mapCloseCardButton}>
            <CloseRoundedIcon
              sx={{
                color: 'white',
                height: '14px',
                cursor: 'pointer',
              }}
              onClick={() => {
                return setSelected(null)
              }}
            />
          </div>
          {mapsResult?.photos
            ?.filter((p) => p.firestoreUri)
            ?.map((photo) => (
              <img
                key={photo.name}
                className={mapHeaderImage}
                style={{ minWidth: '120px' }}
                src={photo.firestoreUri}
                alt="."
              />
            ))}
          {/* deprecate photos_url */}
          {mapsResult?.photos_urls?.map((url) => (
            <img
              key={url}
              className={mapHeaderImage}
              style={{ minWidth: '120px' }}
              src={url}
              alt="."
            />
          ))}
          {discount.logo && <img className={mapHeaderImage} alt="." src={discount.logo} />}
          <img className={mapHeaderImage} src={discount.bgimage} alt="." />
        </div>
        {!focusMap && cardBody}
      </div>
    </div>
  )
}
