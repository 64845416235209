import { CategoriesEnum } from '../types/categories'
import { RegionesEnum } from '../types/regiones'

export const ALL_CATEGORIES = [
  CategoriesEnum.RESTOBAR,
  CategoriesEnum.GAS,
  CategoriesEnum.LIQUOR,
  CategoriesEnum.CINEMA,
  CategoriesEnum.PHARMACY,
  CategoriesEnum.CAR_RENTAL,
  CategoriesEnum.COURSES,
  CategoriesEnum.SPORTS,
  CategoriesEnum.HEALTH,
  CategoriesEnum.OTHERS,
]

export const regiones: RegionesEnum[] = [
  RegionesEnum.METROPOLITANA,
  RegionesEnum.ANTOFAGASTA,
  RegionesEnum.ARAUCANIA,
  RegionesEnum.ARICA,
  RegionesEnum.ATACAMA,
  RegionesEnum.AYSEN,
  RegionesEnum.BIOBIO,
  RegionesEnum.COQUIMBO,
  RegionesEnum.LAGOS,
  RegionesEnum.LIBERTADOR,
  RegionesEnum.MAGALLANES,
  RegionesEnum.MAULE,
  RegionesEnum.NUBLE,
  RegionesEnum.RIOS,
  RegionesEnum.TARAPACA,
  RegionesEnum.VALPARAISO,
]

export const shortRegionName = {
  // para el logger
  METROPOLITANA: 'RM',
  ANTOFAGASTA: 'ANTO',
  ARAUCANIA: 'ARA',
  ARICA: 'ARI',
  ATACAMA: 'ATA',
  AYSEN: 'AYS',
  BIOBIO: 'BIO',
  COQUIMBO: 'COQ',
  LAGOS: 'LAG',
  LIBERTADOR: 'LIB',
  MAGALLANES: 'MAG',
  MAULE: 'MAU',
  NUBLE: 'NUB',
  RIOS: 'RIO',
  TARAPACA: 'TAR',
  VALPARAISO: 'VAL',
}

export const sortOptions = {
  RELEVANCE: 'Más vistos',
  DCTO: 'Mayor descuento',
  KMS: 'Menor distancia',
}

export const benefitTypes = {
  ALL_DISCOUNTS: 'Sí',
  NOT_ALL: 'No',
}

export const mapOptions = {
  INCLUIR_FRANQUICIAS: 'Incluir cadenas',
}

export const DEFAULT_FILTERS = {
  // SIEMPRE QUE SE AGREGUE UN FILTRO DEBE AGREGARSE ACA
  Dias: [],
  MainTag: [],
  Consumo: [],
  Ubicacion: [],
  Tarjetas: [],
  Company: null,
  MinDiscountsAmount: 0,
  BenefitType: [],
  Mapa: [],
  Sort: [sortOptions.RELEVANCE],
}

export const optionLabel: { [key: string]: string } = {
  L: 'Lunes',
  M: 'Martes',
  W: 'Miércoles',
  J: 'Jueves',
  V: 'Viernes',
  S: 'Sábados',
  D: 'Domingos',
  Dias: 'Días',
  MainTag: 'Categorías',
  Ubicacion: 'Región',
  BenefitType: '¿Quiéres ver beneficios que no son descuentos?',
  cursos: 'Educación',
  Itau: 'Itaú',
  [CategoriesEnum.RESTOBAR]: 'Restaurantes, bares y cafés',
  [CategoriesEnum.CAR_RENTAL]: 'Arriendo de autos y transportes',
  Sort: 'Ordenar por',
  MinDiscountsAmount: '% de Dcto.',
  Tarjetas: 'Convenios',
  Company: 'Empleo',
  // regiones
  ARICA: 'Arica y Parinacota',
  AYSEN: 'Aysén',
  TARAPACA: 'Tarapacá',
  ANTOFAGASTA: 'Antofagasta',
  ATACAMA: 'Atacama',
  COQUIMBO: 'Coquimbo',
  VALPARAISO: 'Valparaíso',
  METROPOLITANA: 'Metropolitana',
  LIBERTADOR: 'O’Higgins',
  MAULE: 'Maule',
  NUBLE: 'Ñuble',
  BIOBIO: 'Biobío',
  ARAUCANIA: 'La Araucanía',
  RIOS: 'Los Ríos',
  LAGOS: 'Los Lagos',
  MAGALLANES: 'Magallanes y Antártica',
  Consumo: 'Presencial o Delivery',
  FullCopec: 'Full Copec',
}

export const daysIndex: { [key: number]: string } = {
  0: 'D',
  1: 'L',
  2: 'M',
  3: 'W',
  4: 'J',
  5: 'V',
  6: 'S',
}
