import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Card, Chip } from '@mui/material'
import { request } from '../../helpers/request'
import { AppContext, UserAuthType, UserRole } from '../../contexts/AppContext'
import { SMContent, SMVerticalDiv, SMView } from '../../SMComponents/SMView'
import { SMH2, SMP } from '../../SMComponents/SMText'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'

import { useCookies } from 'react-cookie'
import { formatRut, isValidPhoneNumber, isValidRut } from '../../helpers/validateForms'
import { SMMuiTelInput, SMTextField } from '../../SMComponents/SMInput'
import { SMPrimaryButton } from '../../SMComponents/SMButton'
import { useNavigate } from 'react-router-dom'

export const AdminLogin = () => {
  const { auth, setAuth } = useContext(AppContext)
  const navigate = useNavigate()

  const setCookie = useCookies(['secretToken', 'userId'])[1]
  const [tryAgain, setTryAgain] = useState(false)
  const [password, setPassword] = useState('')
  const [rut, setRut] = useState(auth?.rut || '')
  const [phoneNumber, setPhoneNumber] = useState(auth?.phoneNumber || '+56')
  const [isLoading, setIsLoading] = useState(false)

  const redirectLoggedIn = (user: UserAuthType) => {
    if (user.role === UserRole.ADMIN) {
      return navigate('/admin/provider')
    } else if (user.role === UserRole.PROVIDER) {
      return navigate('/admin/provider')
    } else if (user.role === UserRole.COMPANY) {
      return navigate('/admin/company')
    }
  }

  useEffect(() => {
    if (auth?.role && auth?.role !== UserRole.USER) {
      redirectLoggedIn(auth)
    }
  }, [auth])

  const handleLoginAdmin = async () => {
    setIsLoading(true)
    const response = await request('login_admin', {
      method: 'POST',
      body: JSON.stringify({
        rut,
        phoneNumber,
        password,
      }),
    })
    if (response?.data?.success && response?.data?.user) {
      const newAuth = response.data.user as UserAuthType
      const { secretToken, id: userId } = newAuth
      setCookie('secretToken', secretToken, { path: '/', expires: new Date(9999999999999) })
      setCookie('userId', userId, { path: '/', expires: new Date(9999999999999) })
      setAuth(newAuth)
      setTryAgain(false)
      redirectLoggedIn(newAuth)
    } else {
      setTryAgain(true)
    }
    setIsLoading(false)
  }

  return (
    <SMView horizontalCenter verticalCenter>
      <Card sx={{ p: 10 }}>
        <SMContent verticalCenter sx={{ maxWidth: 600 }}>
          <SMVerticalDiv horizontalCenter>
            <Avatar sx={{ width: 64, height: 64 }}>
              <PersonRoundedIcon sx={{ width: 32, height: 32 }} />
            </Avatar>
            <SMH2>Inicia sesión como administrador</SMH2>
            <SMP center>{tryAgain && <Chip label="Error en sus credenciales" color="error" />}</SMP>
          </SMVerticalDiv>

          <SMMuiTelInput
            validateOnBlur={isValidPhoneNumber}
            textOnBlurError="Número inválido"
            value={phoneNumber}
            onChange={(e: string) => setPhoneNumber(e)}
          />
          <SMTextField
            validateOnBlur={isValidRut}
            textOnBlurError="Rut inválido"
            type="numeric"
            label="Rut"
            value={rut}
            onChange={(e: any) => {
              const rut = formatRut(e.target.value)
              setRut(rut)
            }}
          />
          <SMTextField
            label="Contraseña"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <SMPrimaryButton
            disabled={!isValidPhoneNumber(phoneNumber) || !isValidRut(rut) || password.length < 4}
            onClick={handleLoginAdmin}
            loading={isLoading}
            fullWidth
          >
            Iniciar Sesión
          </SMPrimaryButton>
          <SMP>Si olvidaste la contraseña puedes contactar al +56974769848</SMP>
        </SMContent>
      </Card>
    </SMView>
  )
}
