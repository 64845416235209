import React, { useContext, useState } from 'react'

import TransferWithinAStationRoundedIcon from '@mui/icons-material/TransferWithinAStationRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'

import styles from './discountCard.module.css'
import { showTwoDecimals } from '../../../helpers/location'
import { generateDiscountTitle, normalizeDaysToText } from '../../../helpers/others'
import {
  neutral50White,
  neutralBlack,
  neutralWhite,
  primaryPrimary,
  secondary40White,
} from '../../../style'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import { capitalizeFirstChar } from '../../../helpers/normalizeText'
import { request } from '../../../helpers/request'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import { ShareButton } from '../../common/SMButton'
import { Discount } from '../../../types/discount'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMIconButton, SMPrimaryButton } from '../../../SMComponents/SMButton'
import { DiscountContext } from '../../../contexts/DiscountContext'
import {
  RESERVE_FIELDS_REQUIRED,
  SAVE_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToReserved,
  isAuthAllowedToSaveDiscounts,
} from '../../../consts/loginRestrictions'
import { SMChip } from '../../../SMComponents/SMChip'
import { SMP } from '../../../SMComponents/SMText'
import { CategoriesEnum } from '../../../types/categories'
import { StarOptions } from '../../admin/components/StarOptions'

const {
  discountCard,
  discountHeaderFrame,
  headerClubBorder,
  headerClub,
  headerBGImage,
  backgroundImage,
  logoImage,
  discountBodyFrame,
  discountTitle,
  allConditionsFrame,
} = styles
const isProd = process.env.NODE_ENV === 'production'

const DiscountCard = function ({
  discount,
  isStar,
  handleDiscountClick,
  onDelete,
  onEdit,
}: {
  discount: Discount
  isStar: boolean
  handleDiscountClick: (discount: any) => void
  onDelete?: (discount: any) => void
  onEdit?: (discount: any) => void
}) {
  // escribe discount.XClosestLocation y discount.XGoogleMapsLink
  const { filtersSelected, auth, setAuth, handleOpenSnackBar, clubs } = useContext(AppContext)
  const { handleOpenLoginDrawer, handleOpenReportErrorDrawer, handleOpenReservationDrawer } =
    useContext(DiscountContext)
  const companySelectedIcon = filtersSelected?.Company?.icon
  const [isSaved, setIsSaved] = useState<boolean>(
    auth?.savedDiscounts?.includes(discount.id) || false
  )

  const showDistance =
    discount.XClosestLocation && (discount.XClosestLocation.distance < 10 || !isProd)

  const onDiscountCardClicked = () => {
    logSMEvent('DISCOUNT_CARD_CLICKED', {
      id: discount.id,
      url: discount.url,
      mainTag: discount.mainTag,
      club: discount.club,
      provider: discount?.provider || 'none',
      company: filtersSelected?.Company?.code || 'none',
    })
    handleDiscountClick(discount)
  }

  const handleReservation = () => {
    const hasToCompleteLoginFields = !isAuthAllowedToReserved(auth)
    logSMEvent('RESERVATION_BUTTON_CLICKED_0', {
      id: discount.id,
      url: discount.url,
      hasToCompleteLoginFields,
      phoneNumber: auth?.phoneNumber,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleAction = (userAuth: UserAuthType) => {
      handleOpenReservationDrawer({
        source: 'discount_card',
        discount,
        hasUserJustLoggedIn: hasToCompleteLoginFields, // no usamos userAuth, xq ese es el nuevo post login
      })
    }
    if (!auth || hasToCompleteLoginFields) {
      handleOpenLoginDrawer({
        source: 'reservation_button',
        title: 'Completa tu perfil para reservar una mesa.',
        subtitle:
          'Además de reservar una mesa, podrás acceder a guardar descuentos, ver beneficios exclusivos y más.',
        fields: RESERVE_FIELDS_REQUIRED,
        buttonLabel: 'Reservar mesa',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  const handleSaveDiscount = () => {
    const handleAction = async (userAuth: UserAuthType) => {
      if (!isSaved) {
        logSMEvent('DISCOUNT_SAVED', {
          id: discount.id,
          url: discount.url,
          mainTag: discount.mainTag,
          club: discount.club,
          provider: discount?.provider || 'none',
          company: filtersSelected?.Company?.code || 'none',
        })
      }
      setIsSaved(!isSaved)
      handleOpenSnackBar({
        message: isSaved ? 'Descuento retirado' : 'Descuento guardado ',
      })
      const response = await request(
        'save_discount',
        {
          method: 'POST',
          body: JSON.stringify({
            discountId: discount.id,
            save: !isSaved,
          }),
        },
        userAuth
      )
      const newListOfDiscounts = (response?.data?.savedDiscounts as string[]) || null
      setAuth({
        ...userAuth,
        savedDiscounts: newListOfDiscounts ? newListOfDiscounts : userAuth.savedDiscounts,
      })
    }
    if (!auth || !isAuthAllowedToSaveDiscounts(auth)) {
      handleOpenLoginDrawer({
        source: 'like_button',
        title: 'Completa tu perfil para guardar descuentos',
        subtitle:
          'Podrás guardar descuentos, reservar mesas, acceder a beneficios exclusivos y más.',
        fields: SAVE_DISCOUNTS_FIELDS_REQUIRED,
        buttonLabel: 'Guardar descuento',
        onSubmit: handleAction,
      })
    } else {
      handleAction(auth)
    }
  }

  return (
    <div
      className={discountCard}
      key={discount.id}
      onClick={onDiscountCardClicked}
      style={{
        cursor: 'pointer',
      }}
    >
      <div className={discountHeaderFrame}>
        <img // background para que se vea lindo atras de la imagen
          className={backgroundImage}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        <img
          className={headerBGImage}
          style={{
            objectFit:
              discount.club == 'Claro' || discount.club == 'Lider Bci' ? 'contain' : 'cover',
          }}
          src={discount.bgimage}
          alt="."
          referrerPolicy="no-referrer"
        />
        <div className={headerClubBorder}>
          <div className={headerClub}>
            {discount.club ? (
              <img
                src={clubs[discount.club]?.img}
                style={{ width: clubs[discount.club]?.width }}
                alt={discount.club}
              />
            ) : discount.company ? (
              <img src={discount.companyLogo} style={{ width: 32 }} alt="C." />
            ) : discount.provider ? (
              <img src={discount.providerLogo} style={{ width: 32 }} alt="P." />
            ) : null}
          </div>
        </div>
        {discount.providerLogo && companySelectedIcon && (
          <div
            style={{
              position: 'absolute',
              left: '50px',
              top: '60px',
              width: '28px',
              height: '28px',
              display: 'flex',
              border: '1px solid #E0E0E0',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '4px',
            }}
          >
            <img src={companySelectedIcon} style={{ width: 24, height: 24 }} alt="P." />
          </div>
        )}
        {discount.logo && discount.logo !== discount.bgimage ? (
          <img className={logoImage} alt="." src={discount.logo} />
        ) : (
          <div />
        )}
      </div>
      <div className={discountBodyFrame}>
        <p className={discountTitle}>{generateDiscountTitle(discount)}</p>
        {showDistance && (
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <TransferWithinAStationRoundedIcon sx={{ marginRight: '10px' }} />
            <p style={{ display: 'flex', paddingTop: '7px', alignItems: 'center' }}>
              a {showTwoDecimals(discount.XClosestLocation?.distance) || ''} km aprox.
            </p>
          </div>
        )}
        {isStar && <StarOptions discount={discount} />}

        <div className={allConditionsFrame}>
          {/* 
          TODO: hacer que aparezca en amarillo o rojo si es muy cercano a la fecha, si no que no aparezca.
          */}
          {discount.subTarjeta && (
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <CreditCardRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <p style={{ color: neutral50White, marginLeft: '8px' }}>
                {capitalizeFirstChar(discount.subTarjeta)
                  .replaceAll('�', 'é')
                  .replaceAll(';', ', ')}
              </p>
            </div>
          )}
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
            <p style={{ color: neutral50White, marginLeft: '8px' }}>
              {normalizeDaysToText(discount.diasNormalizados)}
            </p>
          </div>
          {discount.regiones && discount.mainTag?.includes(CategoriesEnum.RESTOBAR) && (
            <div style={{ display: 'flex' }}>
              <LocationOnRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <p style={{ color: neutral50White, marginLeft: '8px' }}>
                {discount.regiones.length > 2
                  ? discount.regiones
                      .slice(0, 2)
                      .map((r) => capitalizeFirstChar(r.toLowerCase()))
                      .join(', ') +
                    ' y ' +
                    (discount.regiones.length - 2) +
                    ' más'
                  : discount.regiones?.map((r) => capitalizeFirstChar(r.toLowerCase())).join(', ')}
              </p>
            </div>
          )}
        </div>
        <SMHorizontalDiv maxSpaceBetween>
          <SMHorizontalDiv>
            <SMChip
              onClick={() =>
                handleOpenReportErrorDrawer({
                  source: 'discount_card',
                  discount,
                })
              }
            >
              <FlagRoundedIcon sx={{ color: secondary40White }} />
              <p style={{ color: neutralBlack }}>Reportar un error</p>
            </SMChip>
          </SMHorizontalDiv>
          <SMHorizontalDiv>
            <SMIconButton onClick={handleSaveDiscount}>
              {isSaved ? (
                <BookmarkRoundedIcon sx={{ color: secondary40White }} />
              ) : (
                <BookmarkBorderRoundedIcon sx={{ color: secondary40White }} />
              )}
            </SMIconButton>
            <ShareButton
              discount={discount}
              buttonname="discount_small_card_share_button"
              sx={{
                border: '2px solid',
                borderColor: primaryPrimary,
                color: primaryPrimary,
                backgroundColor: neutralWhite,
                minHeight: '0px',
                maxHeight: 'fit-content',
                padding: '2px 2px',
                margin: '-20px 0px 0px 0px',
                maxWidth: 'fit-content',
                minWidth: 0,
              }}
            >
              <ShareRoundedIcon />
            </ShareButton>
          </SMHorizontalDiv>
        </SMHorizontalDiv>
        {discount.isReservable &&
          discount.reservationLink &&
          discount.tipoConsumo?.includes('presencial') && (
            <SMPrimaryButton
              fullWidth
              small
              sx={{ margin: '4px 0px' }}
              onClick={(e: any) => {
                e.preventDefault() // prevent the default action of the browser
                e.stopPropagation() // prevent the click from propagating to the parent
                handleReservation()
              }}
            >
              Reservar mesa
            </SMPrimaryButton>
          )}

        {discount?.updatedAt?._seconds && (
          <SMP small sx={{ textAlign: '' }}>
            Última actualización:{' '}
            {new Intl.DateTimeFormat('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            }).format(new Date(discount.updatedAt._seconds * 1000))}
          </SMP>
        )}
        {isStar && (
          <>
            <SMP small sx={{ textAlign: '' }}>
              AI actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.aiQueryOutputTime?._seconds * 1000) | 0))}
            </SMP>
            <SMP small sx={{ textAlign: '' }}>
              GMaps actualización:{' '}
              {new Intl.DateTimeFormat('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              }).format(new Date((discount?.gMapsOutputTime?._seconds * 1000) | 0))}
            </SMP>
          </>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
        {onEdit && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              onEdit(discount)
            }}
          >
            Editar
          </button>
        )}
        {onDelete && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              onDelete(discount)
            }}
          >
            Eliminar
          </button>
        )}
      </div>
    </div>
  )
}

export default DiscountCard
