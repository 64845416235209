import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { primary40Black, primaryPrimary, neutralWhite } from '../../../../style'
import { DiscountMapCard } from './DiscountMapCard'
import { Map } from './Map'
import { DiscountMarker } from './DiscountMarker'
import ButtonTracked from '../../../common/ButtonTracked'
import { AppContext } from '../../../../contexts/AppContext'
import { Alert, Collapse, Switch } from '@mui/material'
import { getUsersLocation } from '../../../../hooks/useCurrentLocation'
import NoDiscountsFound from '../NoDiscountsFound'
import { mapOptions } from '../../../../consts/filterOptions'
import RestobarMustBeSelectedToSeeMap from '../RestobarMustBeSelectedToSeeMap'
import { CategoriesEnum } from '../../../../types/categories'
import { SMP } from '../../../../SMComponents/SMText'

const isProd = process.env.NODE_ENV === 'production'

const restaurantTypes = ['restaurant', 'meal_delivery', 'meal_takeaway', 'bar', 'cafe', 'bakery']

const FindMeButton = ({ mapRef, usersLocation, setUsersLocation, setShowAlert }) => {
  useEffect(() => {
    if (usersLocation?.error) {
      setShowAlert(true)
    } else if (usersLocation?.latitude && usersLocation?.longitude) {
      mapRef.current?.panTo({
        lat: usersLocation.latitude,
        lng: usersLocation.longitude,
      })
    }
  }, [usersLocation])
  return (
    <ButtonTracked
      buttonname="find_me_button"
      onClick={() => {
        getUsersLocation(usersLocation, setUsersLocation)
      }}
      variant="outlined"
      disableRipple={false} // enable wave effect
      style={{
        display: 'flex',
        position: 'absolute',
        top: '140px',
        right: '20px',
        zIndex: 10,
        borderRadius: '10px',
        borderColor: neutralWhite,
        backgroundColor: neutralWhite,
        textTransform: 'none',
        minWidth: '0px',
        margin: '0px',
        padding: '0px',
        // shadow
        boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
      }}
    >
      <NearMeRoundedIcon
        style={{
          fontSize: '30px',
          padding: '6px',
          color: primaryPrimary,
        }}
      />
    </ButtonTracked>
  )
}

const FranquiciasFilterButton = () => {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  return (
    <ButtonTracked
      buttonname="ver_cadenas_button"
      onClick={() => {
        setFiltersSelected({
          ...filtersSelected,
          Mapa: filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)
            ? []
            : [mapOptions.INCLUIR_FRANQUICIAS],
        })
      }}
      variant="outlined"
      disableRipple={false} // enable wave effect
      style={{
        display: 'flex',
        position: 'absolute',
        top: '140px',
        left: '20px',
        zIndex: 10,
        borderRadius: '10px',
        borderColor: neutralWhite,
        backgroundColor: neutralWhite,
        textTransform: 'none',
        minWidth: '0px',
        margin: '0px',
        padding: '2px 12px',
        // shadow
        boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
      }}
    >
      <SMP>Ver cadenas</SMP>
      <Switch checked={filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)} />
    </ButtonTracked>
  )
}

export function MapScreen({
  star,
  discountsToShow,
  isLoadingDiscounts,
  search,
  handleChangeSearch,
  setNavButtonClicked,
}) {
  const { usersLocation, setUsersLocation, filtersSelected } = useContext(AppContext)
  const [showAlert, setShowAlert] = useState(false)
  const [focusMap, setFocusMap] = useState(true)
  const [markers, setMarkers] = useState([])
  const [approvedGMapsResults, setApprovedGMapsResults] = useState([])
  const [removedGMapsResults, setRemovedGMapsResults] = useState([])
  const mapRef = useRef()
  const [selected, setSelected] = useState(null)

  const handleMarkerClick = (isSelected, discount, gMapResult) => {
    if (isSelected) {
      setSelected(null)
      setFocusMap(true)
    } else {
      setFocusMap(false)
      !isProd && console.log(discount)
      setSelected({ discount, gMapResult })
    }
  }

  let idMarkerSelected = ''
  if (selected?.gMapResult?.geometry?.lat) {
    idMarkerSelected = `${selected.discount.id}${selected.gMapResult.geometry.lat}${selected.gMapResult.geometry.lng}`
  } else if (selected?.gMapResult?.location?.latitude) {
    // GMapsResultsV2
    idMarkerSelected = `${selected.discount.id}${selected.gMapResult.location.latitude}${selected.gMapResult.location.longitude}`
  }

  useEffect(() => {
    const allLocationsDisplayed = []
    const markersInMap = discountsToShow
      .filter((d) => d.gMapsResults && d.cantidadDescuento)
      .filter((d) => d.mainTag?.includes(CategoriesEnum.RESTOBAR))
      .filter((d) => d.tipoConsumo?.includes('presencial'))
      .filter((d) => {
        if (filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)) {
          return true
        } else {
          // filter discounts that have more than 5 locations in gMapsResults
          return d.gMapsResults.length <= 5
        }
      })
      .sort((a, b) => {
        // para que el que tenga mayor descuento este sobre el de menor
        return b.cantidadDescuento - a.cantidadDescuento
      })
      .map((discount) => {
        const gMapsResults = discount.gMapsResults
        return gMapsResults.map((gMapResult) => {
          let location = {}
          if (gMapResult?.geometry?.lat) {
            location = gMapResult.geometry
          } else if (gMapResult?.location?.latitude) {
            // GMapsResultsV2
            location = gMapResult.location
            location.lat = gMapResult.location.latitude
            location.lng = gMapResult.location.longitude
            gMapResult.place_id = gMapResult.id
          }
          const idMarker = `${discount?.id}${location?.lat}${location?.lng}`
          const isSelected = idMarkerSelected === idMarker
          const key = `${idMarker}${Math.random()}`
          const isApproved =
            gMapResult?.approved || approvedGMapsResults.includes(gMapResult.place_id)
          const isRemoved = removedGMapsResults.includes(gMapResult.place_id)
          // black background if none of the gMapResult types is a restaurant
          const paintBackground =
            star && !gMapResult.types.some((type) => restaurantTypes.includes(type))
          let locationString = `${location.lat}${location.lng}`
          while (allLocationsDisplayed.includes(locationString)) {
            // change location a little bit to avoid overlapping
            location.lat += 0.00005
            location.lng += 0.00005
            locationString = `${location.lat}${location.lng}`
          }
          allLocationsDisplayed.push(locationString)

          return (
            <DiscountMarker
              star={star}
              discount={discount}
              handleMarkerClick={() => {
                handleMarkerClick(isSelected, discount, gMapResult)
              }}
              isSelected={isSelected}
              location={location}
              key={key}
              isApproved={isApproved}
              isRemoved={isRemoved}
              paintBackground={paintBackground}
            />
          )
        })
      })
    setMarkers(markersInMap.flat())
  }, [discountsToShow, idMarkerSelected, filtersSelected.Mapa])

  if (isLoadingDiscounts) {
    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          // margin: '0 -16px -16px -16px',
          color: primary40Black,
        }}
      >
        Loading map
      </div>
    )
  }
  if (!filtersSelected?.MainTag?.includes(CategoriesEnum.RESTOBAR)) {
    return <RestobarMustBeSelectedToSeeMap />
  }

  if (discountsToShow.length === 0) {
    return (
      <NoDiscountsFound
        search={search}
        handleChangeSearch={handleChangeSearch}
        setNavButtonClicked={setNavButtonClicked}
        isStar={star}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        // margin: '0 -16px -16px -16px',
      }}
    >
      <Collapse
        in={showAlert}
        style={{
          top: '120px',
          position: 'absolute',
          width: '100%',
          zIndex: 20,
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            return setShowAlert(false)
          }}
        >
          Para encontrarte debes habilitar la ubicación en tu dispositivo. Error:{' '}
          {usersLocation?.error}
        </Alert>
      </Collapse>
      <FindMeButton
        mapRef={mapRef}
        usersLocation={usersLocation}
        setUsersLocation={setUsersLocation}
        setShowAlert={setShowAlert}
      />
      <FranquiciasFilterButton />
      <Map
        mapRef={mapRef}
        usersLocation={usersLocation}
        discountsToShow={discountsToShow}
        handleMarkerClick={handleMarkerClick}
        idMarkerSelected={idMarkerSelected}
        focusMap={() => {
          setFocusMap(true)
        }}
        markers={markers}
      />
      <DiscountMapCard
        star={star}
        selected={selected}
        setSelected={setSelected}
        focusMap={focusMap}
        setFocusMap={setFocusMap}
        approvedGMapsResults={approvedGMapsResults}
        removedGMapsResults={removedGMapsResults}
        setApprovedGMapsResults={setApprovedGMapsResults}
        setRemovedGMapsResults={setRemovedGMapsResults}
      />
    </div>
  )
}
