import { createContext } from 'react'

export type OpenSnackBarProps = {
  message: string
}

export const defaultSnackBarProps: OpenSnackBarProps = {
  message: '',
}

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  PROVIDER = 'PROVIDER',
  COMPANY = 'COMPANY',
  CLUB = 'CLUB',
  STORE = 'STORE',
}

export type UserAuthType = {
  id: string
  secretToken: string
  rut: string
  name?: string
  email?: string
  phoneNumber?: string
  userType?: string
  username?: string
  savedDiscounts?: string[]
  role?: UserRole
  entityId?: string
}

export type ClubsType = {
  [key: string]: {
    category: string
    id: string
    display: boolean
    img: string
    label: string
    width: number
    order: number
  }
}

interface AppContextType {
  user: string
  clubs: ClubsType
  filtersSelected: any
  setFiltersSelected: (filters: any) => void
  usersLocation: any
  setUsersLocation: (location: any) => void
  auth: UserAuthType | null
  setAuth: (auth: UserAuthType | null) => void
  openSnackBar: boolean
  handleOpenSnackBar: (props: OpenSnackBarProps) => void
}

export const AppContext = createContext<AppContextType>({
  user: '',
  clubs: {},
  filtersSelected: {},
  setFiltersSelected: () => {},
  usersLocation: {},
  setUsersLocation: () => {},
  auth: null,
  setAuth: () => {},
  openSnackBar: false,
  handleOpenSnackBar: () => {},
})
