import React from 'react'

import '../../../App.css'
import './landing.css'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useEffect } from 'react'
import { useViewportHeight } from '../../../hooks/useViewportHeight'
import { PrimaryButton } from '../../common/SMButton'
import logoTransparentBackground from '../../../assets/logo/logoTransparentBackground.png'
import { neutralWhite, primary60White, primaryPrimary } from '../../../style'
import { appVersion } from '../../../consts/version'
import { logEventPageVisited, logSMEvent } from '../../../events/logEvent'
import { ReactComponent as InstagramIcon } from '../../../assets/instagramIcon.svg'

export const Landing = function () {
  const navigate = useNavigate()
  const [cookies] = useCookies(['savemoney'])
  useEffect(() => {
    logEventPageVisited(cookies.user, 'first_landing')
  }, [cookies.user])
  const viewportHeight = useViewportHeight()
  const maxWidth = '950px'
  const isSmallScreen = viewportHeight < 600
  const marginLeft = 0

  return (
    <div className="landing-full-screen">
      <div className="landing-holder">
        <div className="landing-main-page" style={{ minHeight: viewportHeight, maxWidth }}>
          <div className="landing-header">
            <h1
              style={{
                margin: 0,
              }}
            >
              SaveMoney
            </h1>
          </div>
          <div className="landing-body">
            <img
              src={logoTransparentBackground}
              style={{
                maxWidth: isSmallScreen ? '15vh' : '17vh',
                maxHeight: isSmallScreen ? '15vh' : '17vh',
                filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25))',
              }}
              alt="TODO"
            />
          </div>
          {/* isSmallScreen ? '40%' : */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '700px', marginLeft }}>
              <h2>Encuentra todos tus beneficios en un solo lugar📱💳💰</h2>
              <p className="body-1">
                La aplicación que reune todos los beneficios de tus tarjetas bancarias, tu trabajo,
                tus tarjetas vecinos, tu compañia de celular, tus billeteras digitales y más.
              </p>
            </div>
          </div>
          <div className="landing-footer" style={{ height: '25%' }}>
            <div className="landing-actions">
              <p>
                Al continuar aceptas nuestros
                <a
                  href="https://www.savemoney.cl/terminos.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primary60White, marginLeft: 3 }}
                  onClick={() =>
                    logSMEvent('CLICK_TERMINOS_Y_CONDICIONES', { page: 'second_landing' })
                  }
                >
                  términos y condiciones
                </a>
              </p>
              <PrimaryButton
                onClick={() => {
                  navigate('/clubs')
                }}
                buttonname="landing_see_discounts"
                sx={{ backgroundColor: neutralWhite }}
              >
                <p className="button-1" style={{ color: primaryPrimary }}>
                  Vamos
                </p>
              </PrimaryButton>
            </div>
            <div className="landing-sign">
              <div style={{ display: 'flex' }}>
                Siguenos
                <a
                  href="https://www.instagram.com/savemoney.cl/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primary60White, display: 'flex', marginLeft: 3 }}
                  onClick={() => logSMEvent('CLICK_FOLLOW_US', { page: 'first_landing' })}
                >
                  @SaveMoney.cl
                  <InstagramIcon
                    alt="instagramIcon"
                    style={{
                      margin: '2px 0px 0px 2px',
                      fill: primary60White,
                      height: '20px',
                    }}
                  />
                </a>
              </div>
              <p className="body-2">{appVersion}</p>
              <a
                href="mailTo:wayoalamos@savemoney.cl"
                className="body-2"
                style={{
                  textDecoration: 'none',
                  color: neutralWhite,
                }}
              >
                By wayoalamos
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
