import React, { useEffect, useState, useContext } from 'react'
import { useCookies } from 'react-cookie'

import '../../../App.css'
import styles from './discount.module.css'
import { AppContext } from '../../../contexts/AppContext'
import FiltersFrame from '../components/FiltersFrame'
import { filterDiscounts } from '../../../helpers/filterDiscounts'
import { sortOptions } from '../../../consts/filterOptions'
import { logEventPageVisited, logSMEvent } from '../../../events/logEvent'
import { DeviceInfo } from '../components/DeviceInfo'
import { SearchBar } from '../components/SearchBar'
import { MapScreen } from '../components/map/MapScreen'
import { DiscountScreen } from '../components/DiscountScreen'
import { setUrlParam, getUrlParam } from '../../../helpers/others'
import { stringifyFiltersSelected } from '../../../events/logEvent'
import { ProfileScreen } from './ProfileScreen'
import { SavedDiscounts } from './SavedDiscounts'
import { useFilterOptions } from '../../../hooks/filterOptions'

const { discountScreen, searchBarFrame } = styles
export const DiscountPage = function ({
  star,
  isStar,
  isLoadingDiscounts,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
  page,
  setNavButtonClicked,
}) {
  const urlSearch = getUrlParam('search')
  const filterOptions = useFilterOptions()
  const { filtersSelected } = useContext(AppContext)
  const [discountsToShow, setDiscountsToShow] = useState([])
  const [search, setSearch] = useState(urlSearch)
  const [cookies] = useCookies(['savemoney'])

  // cambiar search cada vez que la url cambia, esti es redunante excepto cuando se abre la app
  useEffect(() => {
    setSearch(urlSearch)
  }, [urlSearch])

  const handleChangeSearch = (newSearch) => {
    // cambiar url cada vez que el search cambia
    setUrlParam('search', newSearch)
    setSearch(newSearch)
  }

  // log page visited
  useEffect(() => {
    logEventPageVisited(cookies.user, 'discounts')
  }, [cookies.user])

  // log if user spend more than 15 seconds in the page
  useEffect(() => {
    const timeout = setTimeout(() => {
      // esto no es un boton de hecho, pero lo usamos de todas formas para logear cuando
      // un usuario lleva 15 seg en la pagina
      logSMEvent('INFO_USAGE_AFTER_PERIOD', {
        period: '30s',
        search,
        user: cookies.user,
        amountOfPrevVisits: cookies.amountofvisits16,
        ...stringifyFiltersSelected(filtersSelected),
      })
    }, 30000)
    return () => {
      return clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // filtramos los descuentos
  useEffect(() => {
    if (!isLoadingDiscounts && discounts) {
      if (filtersSelected.Sort.includes(sortOptions.KMS)) {
        setDiscountsToShow(
          filterDiscounts(discountsSortedByKms, search, filtersSelected, filterOptions)
        )
      } else if (filtersSelected.Sort.includes(sortOptions.DCTO)) {
        setDiscountsToShow(
          filterDiscounts(discountsSortedByDcto, search, filtersSelected, filterOptions)
        )
      } else {
        setDiscountsToShow(filterDiscounts(discounts, search, filtersSelected, filterOptions))
      }
    }
  }, [
    discounts,
    isLoadingDiscounts,
    search,
    filtersSelected,
    discountsSortedByDcto,
    discountsSortedByKms, // this should change every time the users location changes
    filterOptions,
  ])

  const [scrollDirection, setScrollDirection] = useState('up')
  const [lastScrollPosition, setLastScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset
      const direction = currentScrollPosition > lastScrollPosition ? 'down' : 'up'
      setScrollDirection(direction)
      setLastScrollPosition(currentScrollPosition)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      return window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollPosition])

  const showFilters = scrollDirection === 'up' || lastScrollPosition < 100
  if (search === 'infodeldispositivo' || search === 'Infodeldispositivo') {
    return <DeviceInfo />
  }
  if (search === 'starstarstar') {
    window.location.href = 'http://savemoney.cl/star'
    return
  }
  if (page === 'map')
    return (
      <div className={discountScreen}>
        <div className={searchBarFrame}>
          <SearchBar search={search} handleChangeSearch={handleChangeSearch} />
        </div>
        <FiltersFrame showFilters={showFilters} setNavButtonClicked={setNavButtonClicked} />
        <MapScreen
          star={star}
          isLoadingDiscounts={isLoadingDiscounts}
          discountsToShow={discountsToShow}
          setNavButtonClicked={setNavButtonClicked}
          search={search}
          handleChangeSearch={handleChangeSearch}
        />
      </div>
    )
  if (page === 'list')
    return (
      <div className={discountScreen}>
        <div className={searchBarFrame}>
          <SearchBar search={search} handleChangeSearch={handleChangeSearch} />
        </div>
        <FiltersFrame showFilters={showFilters} setNavButtonClicked={setNavButtonClicked} />
        <DiscountScreen
          isLoadingDiscounts={isLoadingDiscounts}
          discountsToShow={discountsToShow}
          discounts={discounts}
          setNavButtonClicked={setNavButtonClicked}
          search={search}
          handleChangeSearch={handleChangeSearch}
          isStar={isStar}
        />
      </div>
    )
  if (page === 'savedDiscounts')
    return <SavedDiscounts isLoadingDiscounts={isLoadingDiscounts} discounts={discounts} />
  return <ProfileScreen />
}
