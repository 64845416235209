export const isiOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
export const isAndroid = /Android/i.test(navigator.userAgent)
export const isInSafari = /^((?!chrome|android|crios|fxios|opera|edg|msie|trident).)*safari/i.test(
  navigator.userAgent
)
export const isAppInstalled =
  window.matchMedia('(display-mode: standalone)').matches ||
  (window?.navigator as any)?.standalone ||
  document?.referrer?.includes('android-app://')
export const isInstagram = navigator?.userAgent?.includes('Instagram')

const isLocalhost = window.location.hostname === 'localhost'

export const shouldShowDownloadAppBanner = !isAppInstalled && (isiOS || isAndroid) && !isLocalhost
export const isMobile = isiOS || isAndroid
