import { useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import {
  ALL_CATEGORIES,
  benefitTypes,
  mapOptions,
  regiones,
  sortOptions,
} from '../consts/filterOptions'

export const useFilterOptions = () => {
  const { clubs } = useContext(AppContext)

  const [filterOptions, setFilterOptions] = useState({
    Dias: ['L', 'M', 'W', 'J', 'V', 'S', 'D'],
    MainTag: ALL_CATEGORIES,
    Consumo: ['Presencial', 'Delivery'],
    Ubicacion: regiones,
    Tarjetas: [], // Initialize with an empty array
    Company: null,
    MinDiscountsAmount: 0,
    BenefitType: [benefitTypes.ALL_DISCOUNTS, benefitTypes.NOT_ALL],
    Mapa: [mapOptions.INCLUIR_FRANQUICIAS],
    Sort: [sortOptions.RELEVANCE, sortOptions.DCTO, sortOptions.KMS],
  })

  useEffect(() => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      Tarjetas: Object.keys(clubs).sort((a, b) => {
        const orderA = clubs[a].order
        const orderB = clubs[b].order
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB
        }
        if (orderA !== undefined) {
          return -1
        }
        if (orderB !== undefined) {
          return 1
        }
        return 0
      }),
    }))
  }, [clubs])

  return filterOptions
}
