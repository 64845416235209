import { useContext, useEffect, useState } from 'react'

import '../../../App.css'
import { Checkbox } from '@mui/material'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, secondary40Black } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { optionLabel } from '../../../consts/filterOptions'
import { SMP } from '../../../SMComponents/SMText'

const filterLabel = 'Dias'

const now = new Date()
const todayIndex = now.getDay() // 0 domingo, 6 sabado
const todayKeyWord = ['D', 'L', 'M', 'W', 'J', 'V', 'S'][todayIndex]

export const DayCheckBox = function ({ day }) {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(day))
  }, [filtersSelected, day])

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        handleFilterSelection(filterLabel, day, filtersSelected, setFiltersSelected)
      }}
      sx={{
        display: 'flex',
        maxWidth: '325px',
        width: '100%',
        flex: 1,
        minHeight: 45,
        maxHeight: 55,
        borderRadius: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginLeft: 16,
          alignItems: 'center',
        }}
      >
        <p className="subtitle-3" style={{ color: checked ? neutralWhite : secondary40Black }}>
          {optionLabel[day] || day}
        </p>
        <SMP sx={{ marginLeft: 10, color: checked ? neutralWhite : secondary40Black }}>
          {todayKeyWord === day ? ' (hoy)' : ''}
        </SMP>
      </div>
      {/* Check Box */}
      <Checkbox
        style={{
          height: '20px',
          marginRight: 16,
        }}
        checked={checked}
        sx={{
          '&.Mui-checked': {
            color: neutralWhite,
          },
        }}
      />
    </ICheckBox>
  )
}
