import { createContext } from 'react'
import { LoginDrawerFieldsEnum } from '../enums/LoginDrawerFieldsEnum'
import { UserAuthType } from './AppContext'
import { Discount } from '../types/discount'
import { defaultDiscount } from '../helpers/deafultDiscount'

export type OpenLoginDrawerProps = {
  source: string
  title: string
  subtitle: string
  fields: LoginDrawerFieldsEnum[]
  buttonLabel: string
  href?: string
  onSubmit: (user: UserAuthType) => void
}
export const defaultLoginDrawerProps: OpenLoginDrawerProps = {
  source: '',
  title: '',
  subtitle: '',
  fields: [],
  buttonLabel: '',
  onSubmit: () => {},
}

export type OpenReportErrorProps = {
  source: string
  discount: Discount
}
export const defaultReportErrorDrawerProps: OpenReportErrorProps = {
  source: '',
  discount: defaultDiscount,
}

export type OpenReservationProps = {
  source: string
  discount: Discount
  hasUserJustLoggedIn: boolean
}
export const defaultReservationDrawerProps: OpenReservationProps = {
  source: '',
  discount: defaultDiscount,
  hasUserJustLoggedIn: false,
}

type DiscountContextType = {
  openLoginDrawer: boolean
  handleOpenLoginDrawer: (props: OpenLoginDrawerProps) => void
  openReportErrorDrawer: boolean
  handleOpenReportErrorDrawer: (props: OpenReportErrorProps) => void
  openReservationDrawer: boolean
  handleOpenReservationDrawer: (props: OpenReservationProps) => void
}

export const DiscountContext = createContext<DiscountContextType>({
  openLoginDrawer: false,
  handleOpenLoginDrawer: () => {},
  openReportErrorDrawer: false,
  handleOpenReportErrorDrawer: () => {},
  openReservationDrawer: false,
  handleOpenReservationDrawer: () => {},
})
