import { Alert, Checkbox, Collapse, Radio, Switch } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Slider from '@mui/material/Slider'

import { benefitTypes, optionLabel, sortOptions } from '../../../../consts/filterOptions'
import {
  doesNotAllowMultipleSelection,
  handleFilterSelection,
  handleSwitchState,
} from '../../../../helpers/handleFilterSelection'
import { AppContext } from '../../../../contexts/AppContext'
import { useFilterOptions } from '../../../../hooks/filterOptions'

const FilterCardContent = function ({ filterLabel }) {
  const { usersLocation, filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()

  const [showAlert, setShowAlert] = useState(false)
  const [distanceIsDisabled, setDistanceIsDisabled] = useState(!!usersLocation?.error)
  useEffect(() => {
    if (
      filterLabel === 'Sort' &&
      filtersSelected?.Sort?.includes(sortOptions.KMS) &&
      usersLocation?.error
    ) {
      setShowAlert(true)
      setDistanceIsDisabled(true)
      handleFilterSelection(filterLabel, sortOptions.RELEVANCE, filtersSelected, setFiltersSelected)
    }
  }, [usersLocation, filtersSelected.Sort])

  const [sliderValue, setSliderValue] = useState(filtersSelected.MinDiscountsAmount)
  useEffect(() => {
    // para que funcione el boton "Reiniciar todas"
    setSliderValue(filtersSelected.MinDiscountsAmount)
  }, [filtersSelected.MinDiscountsAmount])

  if (filterLabel === 'MinDiscountsAmount') {
    const handleSliderChangeCommited = () => {
      setFiltersSelected({
        ...filtersSelected,
        BenefitType: sliderValue > 0 ? [benefitTypes.NOT_ALL] : filtersSelected.BenefitType,
        MinDiscountsAmount: sliderValue,
      })
    }
    const marks = []
    for (let i = 0; i <= 100; i += 10) {
      marks.push({
        value: i,
        label: i === 0 || i === 100 || i === 40 ? `${i}%` : '',
      })
    }
    return (
      <div>
        <h3>{optionLabel[filterLabel] || filterLabel}</h3>
        <p className="body-1" style={{ marginLeft: 0 }}>
          Entre {filtersSelected.MinDiscountsAmount}% y 100% de dcto.
        </p>
        <div style={{ margin: '30px 35px', width: '80%', maxWidth: '500px' }}>
          <Slider
            value={sliderValue}
            step={5}
            getAriaLabel={() => {
              return 'Minimum distance shift'
            }}
            onChange={(e, v) => {
              return setSliderValue(v)
            }}
            onChangeCommitted={handleSliderChangeCommited}
            valueLabelDisplay="auto"
            getAriaValueText={(v) => {
              return `${v}%`
            }}
            disableSwap
            track="inverted"
            marks={marks}
            defaultValue={sliderValue}
          />
        </div>
      </div>
    )
  }

  const filterDescription =
    filterLabel === 'BenefitType' ? (
      <p style={{ marginTop: -10 }}>
        Por ej: 2x1, canje de puntos, dolares-premio, acumulacion de millas ✈️ y pesos por litro de
        bencina ⛽
      </p>
    ) : null
  const allowsMultipleSelection = !doesNotAllowMultipleSelection.includes(filterLabel)

  return (
    <div>
      <Collapse
        in={showAlert}
        style={{
          top: '0%',
          position: 'absolute',
          width: '90%',
          zIndex: '10',
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            return setShowAlert(false)
          }}
        >
          Para ordenar por distancia debes habilitar la ubicación en tu dispositivo. Error:{' '}
          {usersLocation?.error}
        </Alert>
      </Collapse>
      <h3>{optionLabel[filterLabel] || filterLabel}</h3>
      {filterDescription}
      {(allowsMultipleSelection || filterLabel === 'MainTag') && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            color: '#4b4f52',
            marginLeft: '10px',
          }}
        >
          <p>Seleccionar todos</p>
          <Switch
            checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
            onClick={() => {
              handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
            }}
            sx={{ marginLeft: '20px' }}
          />
        </div>
      )}
      {filterOptions[filterLabel].map((option) => {
        const optionIsDisabled = option === sortOptions.KMS && distanceIsDisabled
        const handleClick = () => {
          if (optionIsDisabled) {
            setShowAlert(true)
          } else {
            handleFilterSelection(filterLabel, option, filtersSelected, setFiltersSelected)
            /*
              when changing filersSelected.Sort this will trigger a useEffect in App.js
              that will request for the users location if neccessary
              and chage usersLocation state
              */
          }
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#4b4f52',
              cursor: 'pointer',
            }}
            onClick={handleClick}
            onKeyPress={handleClick}
            role="button"
            tabIndex={0}
            key={option}
          >
            {allowsMultipleSelection ? (
              <Checkbox
                disabled={optionIsDisabled}
                checked={filtersSelected[filterLabel].includes(option)}
              />
            ) : (
              <Radio
                disabled={optionIsDisabled}
                checkedIcon={<CheckCircleRoundedIcon />}
                checked={filtersSelected[filterLabel].includes(option)}
              />
            )}
            <p>{optionLabel[option] || option?.capitalize()}</p>
          </div>
        )
      })}
    </div>
  )
}

export default FilterCardContent
