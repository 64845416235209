import React, { useContext } from 'react'

import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import { AppContext } from '../../../contexts/AppContext'
import { optionLabel } from '../../../consts/filterOptions'
import { neutralBlack, primary20Black, primaryPrimary } from '../../../style'
import FilterButton from './FilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { CategoriesEnum } from '../../../types/categories'

export default function FiltersFrame({ setNavButtonClicked, showFilters }) {
  // when clicking in a filter it will call setNavButtonClicked with the label of the filter
  // which will open SwipeableEdgeDrawer
  const { filtersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()

  const amountCompanySelected = filtersSelected?.Company ? 1 : 0
  let buttonsOrder = [
    // label has to be the same as filterOptions
    {
      label: 'Dias',
      amountOptionsSelected: filtersSelected?.Dias?.length,
      maximumOfOptions: filterOptions['Dias']?.length,
    },
    {
      label: 'MainTag',
      amountOptionsSelected: filtersSelected?.MainTag?.length,
      maximumOfOptions: filterOptions['MainTag']?.length,
    },
    {
      label: 'Tarjetas',
      amountOptionsSelected: filtersSelected?.Tarjetas?.length + amountCompanySelected,
      maximumOfOptions: 99999, // never show that it reached the maximum
    },
    {
      label: 'MinDiscountsAmount',
      amountOptionsSelected: filtersSelected?.MinDiscountsAmount > 0 ? 1 : 0,
      maximumOfOptions: filterOptions['MinDiscountsAmount']?.length,
    },
    {
      label: 'Consumo',
      amountOptionsSelected: filtersSelected.Consumo.length,
      maximumOfOptions: filterOptions['Consumo']?.length,
    },
    {
      label: 'Ubicacion',
      amountOptionsSelected: filtersSelected.Ubicacion.length,
      maximumOfOptions: filterOptions['MinDiscountsAmount']?.length,
    },
  ]
  const isRestobarSelected =
    filtersSelected.MainTag?.includes(CategoriesEnum.RESTOBAR) ||
    filtersSelected.MainTag?.length === 0

  if (!isRestobarSelected) {
    buttonsOrder = buttonsOrder.filter((b) => b.label !== 'Ubicacion' && b.label !== 'Consumo')
  }

  let styleA = {
    // down
    position: 'fixed',
    top: 0,
    WebkitBackdropFilter: 'blur(10px)', // para iOS
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(245, 247, 249, 0.5)',
    left: 0,
    right: 0,
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    zIndex: 5,
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateY(0px)',
    display: 'flex',
    padding: '74px 0 10px 0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    paddingLeft: '15px',
  }
  if (!showFilters) {
    styleA = {
      ...styleA,
      transform: 'translateY(-48px)',
    }
  }

  return (
    <div style={styleA}>
      <FilterButton setNavButtonClicked={setNavButtonClicked} label="all_filters" number={0}>
        <TuneRoundedIcon style={{ fontSize: '20px', color: primaryPrimary }} />
        <p
          className="body-1"
          style={{ color: primaryPrimary, marginLeft: '5px', marginRight: '6px' }}
        >
          Filtros
        </p>
      </FilterButton>
      {buttonsOrder.map((b) => {
        const label = optionLabel[b.label] || b.label
        const number = b.amountOptionsSelected === b.maximumOfOptions ? 0 : b.amountOptionsSelected
        const numberLabel = number > 0 ? `+${number}` : ''
        return (
          <div
            key={b.label}
            style={{
              display: 'flex',
            }}
          >
            <FilterButton setNavButtonClicked={setNavButtonClicked} label={b.label} number={number}>
              <p className="body-1">{label}</p>
              <p
                className="body-1"
                style={{
                  color: numberLabel ? primary20Black : neutralBlack,
                  marginLeft: numberLabel ? 5 : 0,
                }}
              >
                {numberLabel}
              </p>
              <ArrowDropDownRoundedIcon />
            </FilterButton>
          </div>
        )
      })}
    </div>
  )
}
