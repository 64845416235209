import React, { useContext } from 'react'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMH2 } from '../../../SMComponents/SMText'
import { SMContent, SMView } from '../../../SMComponents/SMView'
import { useCookies } from 'react-cookie'
import { AppContext } from '../../../contexts/AppContext'
import { useNavigate } from 'react-router-dom'

export const UnAuthorizedView = () => {
  const { setAuth } = useContext(AppContext)
  const navigate = useNavigate()
  const setCookie = useCookies(['secretToken', 'userId'])[1]
  return (
    <SMView horizontalCenter>
      <SMContent verticalCenter>
        <SMH2 sx={{ color: 'red' }}>Usuario no autorizado :/</SMH2>
        <SMPrimaryButton
          maxWidth="200px"
          onClick={() => {
            setCookie('secretToken', '', { path: '/', expires: new Date(9999999999999) })
            setCookie('userId', '', { path: '/', expires: new Date(9999999999999) })
            setAuth(null)
            return navigate('/admin/login')
          }}
        >
          Cerrar sesión
        </SMPrimaryButton>
      </SMContent>
    </SMView>
  )
}
