import React from 'react'

import '../../../App.css'
import './landing.css'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useContext, useEffect } from 'react'
import { useViewportHeight } from '../../../hooks/useViewportHeight'
import logoTransparentBackground from '../../../assets/logo/logoTransparentBackground.png'
import { PrimaryButton } from '../../common/SMButton'
import { neutralWhite, primary20White, primary60White, primaryPrimary } from '../../../style'
import { appVersion } from '../../../consts/version'
import { logEventPageVisited, logSMEvent } from '../../../events/logEvent'
import { AppContext } from '../../../contexts/AppContext'
import { categoriesIcons } from '../../common/Icons'
import { optionLabel } from '../../../consts/filterOptions'
import { ReactComponent as InstagramIcon } from '../../../assets/instagramIcon.svg'
import { CategoriesEnum } from '../../../types/categories'

const iconStyle = {
  fontSize: 25,
  color: primary20White,
}
const getFirstCategory = (categoriesSelected) => {
  if (categoriesSelected?.length > 0) {
    if (categoriesSelected.includes(CategoriesEnum.RESTOBAR)) {
      return CategoriesEnum.RESTOBAR
    }
    return categoriesSelected[0]
  }
  return CategoriesEnum.RESTOBAR
}

const getFirstClub = (clubsSelected, clubs) => {
  if (clubsSelected?.length > 0) {
    return clubsSelected[0]
  }
  return Object.keys(clubs)[0]
}

export const SecondLanding = function () {
  const navigate = useNavigate()
  const [cookies] = useCookies(['savemoney'])
  useEffect(() => {
    logEventPageVisited(cookies.user, 'second_landing')
  }, [cookies.user])
  const viewportHeight = useViewportHeight()
  const isSmallScreen = viewportHeight < 600
  const { filtersSelected, clubs } = useContext(AppContext)
  const clubsSelected = filtersSelected?.Tarjetas
  const categoriesSelected = filtersSelected?.MainTag
  const firstClub = getFirstClub(clubsSelected, clubs)
  const firstCategory = getFirstCategory(categoriesSelected)
  const amountOfCards = (clubsSelected?.length || Object.keys(clubs).length) - 1
  const maxWidth = '950px'

  return (
    <div className="landing-full-screen">
      <div className="landing-holder">
        <div className="landing-main-page" style={{ minHeight: viewportHeight, maxWidth }}>
          <div className="landing-header">
            <h1
              style={{
                margin: 0,
                textShadow: '2px 2px 2px rgba(0, 0, 0, 0.20)',
              }}
            >
              SaveMoney
            </h1>
          </div>
          <div className="landing-body">
            <img
              src={logoTransparentBackground}
              style={{
                maxWidth: isSmallScreen ? '15vh' : '17vh',
                maxHeight: isSmallScreen ? '15vh' : '17vh',
                filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25))',
              }}
              alt="SaveMoney.cl"
            />
          </div>
          <div className="landing-footer" style={{ height: isSmallScreen ? '60%' : '50%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="last-filters-frame">
                <p className="subtitle-2">Última vez filtraste con:</p>
                <div className="last-filters-used-all">
                  <div className="last-filters-used-single">
                    <div className="last-filters-used-icon">
                      <img
                        src={clubs[firstClub]?.img}
                        style={{ width: clubs[firstClub]?.width }}
                        alt="."
                      />
                    </div>
                    <div className="last-filters-used-text">
                      <p className="subtitle-3 last-filters-used-text-line">
                        {optionLabel[firstClub] || firstClub}
                      </p>
                      <p className="body-1">
                        + {amountOfCards} tarjeta
                        {amountOfCards !== 1 ? 's' : ''}
                      </p>
                    </div>
                  </div>
                  <div className="last-filters-used-single">
                    <div className="last-filters-used-icon">
                      {categoriesIcons(iconStyle)[firstCategory]}
                    </div>
                    <div className="last-filters-used-text">
                      <p className="subtitle-3 last-filters-used-text-line">Categoría:</p>
                      <p className="body-1">
                        {optionLabel[firstCategory]?.capitalize() || firstCategory?.capitalize()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-actions">
              <p>
                Al continuar aceptas nuestros
                <a
                  href="https://www.savemoney.cl/terminos.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primary60White, marginLeft: 3 }}
                  onClick={() =>
                    logSMEvent('CLICK_TERMINOS_Y_CONDICIONES', { page: 'second_landing' })
                  }
                >
                  términos y condiciones
                </a>
              </p>
              <PrimaryButton
                onClick={() => {
                  navigate('/descuentos')
                }}
                buttonname="landing_see_discounts"
                sx={{ backgroundColor: neutralWhite }}
              >
                <p className="button-1" style={{ color: primaryPrimary }}>
                  Ver descuentos
                </p>
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  navigate('/clubs')
                }}
                buttonname="landing_filter_again"
                sx={{ border: '2px solid', borderColor: neutralWhite }}
              >
                <p className="button-1">Volver a filtrar</p>
              </PrimaryButton>
            </div>
            <div className="landing-sign">
              <div style={{ display: 'flex' }}>
                Siguenos
                <a
                  href="https://www.instagram.com/savemoney.cl/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: primary60White, display: 'flex', marginLeft: 3 }}
                  onClick={() => logSMEvent('CLICK_FOLLOW_US', { page: 'second_landing' })}
                >
                  @SaveMoney.cl
                  <InstagramIcon
                    alt="instagramIcon"
                    style={{
                      margin: '2px 0px 0px 2px',
                      fill: primary60White,
                      height: '20px',
                    }}
                  />
                </a>
              </div>
              <p className="body-2">v {appVersion}</p>
              <a
                href="mailTo:wayoalamos@savemoney.cl"
                className="body-2"
                style={{
                  textDecoration: 'none',
                  color: neutralWhite,
                }}
              >
                By wayoalamos
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
