import React from 'react'

import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded'
import VaccinesRoundedIcon from '@mui/icons-material/VaccinesRounded'
import CarRentalRoundedIcon from '@mui/icons-material/CarRentalRounded'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import LiquorRoundedIcon from '@mui/icons-material/LiquorRounded'
import TheatersRoundedIcon from '@mui/icons-material/TheatersRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import { CategoriesEnum } from '../../types/categories'

export const categoriesIcons = (style: any) => {
  return {
    [CategoriesEnum.RESTOBAR]: <RestaurantRoundedIcon style={style} />,
    [CategoriesEnum.GAS]: <LocalGasStationRoundedIcon style={style} />,
    [CategoriesEnum.LIQUOR]: <LiquorRoundedIcon style={style} />,
    [CategoriesEnum.CINEMA]: <TheatersRoundedIcon style={style} />,
    [CategoriesEnum.PHARMACY]: <VaccinesRoundedIcon style={style} />,
    [CategoriesEnum.CAR_RENTAL]: <CarRentalRoundedIcon style={style} />,
    [CategoriesEnum.COURSES]: <SchoolRoundedIcon style={style} />,
    [CategoriesEnum.SPORTS]: <FitnessCenterRoundedIcon style={style} />,
    [CategoriesEnum.HEALTH]: <FavoriteBorderRoundedIcon style={style} />,
    [CategoriesEnum.OTHERS]: <AddCircleRoundedIcon style={style} />,
  }
}

export const consumoIcons = (style: any) => {
  return {
    Presencial: <StoreRoundedIcon style={style} />,
    Delivery: <DeliveryDiningRoundedIcon style={style} />,
  }
}
