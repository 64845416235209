import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import './intro.css'
import '../../../App.css'

import { Button } from '@mui/material'
import { ProgressBar } from '../components/ProgressBar'
import { PrimaryButton } from '../../common/SMButton'
import { useViewportHeight } from '../../../hooks/useViewportHeight'
import { logEventPageVisited } from '../../../events/logEvent'
import { primaryPrimary } from '../../../style'
import { isAppInstalled, isiOS } from '../../../helpers/device'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'

export const IIntro = function ({ pageName, progressNumber, onClickNext, onClickBack, children }) {
  const [cookies] = useCookies(['savemoney'])

  const handleClickBack = () => {
    window.scrollTo(0, 0)
    onClickBack()
  }

  const handleClickNext = () => {
    window.scrollTo(0, 0)
    onClickNext()
  }

  useEffect(() => {
    logEventPageVisited(cookies.user, pageName)
  }, [cookies.user, pageName])

  const viewportHeight = useViewportHeight()

  return (
    <div className="full-screen">
      <div className="holder">
        <div className="main-page" style={{ minHeight: viewportHeight - 32 }}>
          {/* header: */}
          <SMHorizontalDiv maxSpaceBetween sx={{ marginBottom: 18 }}>
            <Button onClick={handleClickBack} style={{ minWidth: 30 }}>
              <ArrowBackIosNewRoundedIcon style={{ color: primaryPrimary }} />
            </Button>
            <ProgressBar progressNumber={progressNumber} />
            <div style={{ minWidth: 30 }} />
          </SMHorizontalDiv>
          {/* body: */}
          {children}
          {/* footer: */}
          <div className="footer" style={isiOS && !isAppInstalled ? { marginBottom: '90px' } : {}}>
            <PrimaryButton onClick={handleClickNext}>
              <p className="button-1">Siguiente</p>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
