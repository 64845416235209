import React, { useContext } from 'react'

import { DEFAULT_FILTERS, benefitTypes } from '../../../../consts/filterOptions'
import { AppContext } from '../../../../contexts/AppContext'
import styles from './filters.module.css'
import ButtonTracked from '../../../common/ButtonTracked'
import { neutral90White } from '../../../../style'
import FilterCardContent from './FilterCardContent'
import { CategoriesEnum } from '../../../../types/categories'

const { filtersFooterFrame, footerButtons, resultsAmount } = styles

const FilterFooterFrame = function ({ filterLabel, toggleDrawer }) {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)

  const handleResetAll = () => {
    const newFiltersSelect = { ...filtersSelected }
    if (filterLabel === 'MinDiscountsAmount') {
      newFiltersSelect.BenefitType = [benefitTypes.ALL_DISCOUNTS]
      newFiltersSelect.MinDiscountsAmount = 0
    } else newFiltersSelect[filterLabel] = [...DEFAULT_FILTERS[filterLabel]]
    setFiltersSelected(newFiltersSelect)
  }

  return (
    <div className={filtersFooterFrame}>
      <div className={resultsAmount} />
      <div className={footerButtons}>
        <ButtonTracked
          buttonname={`filter_card_done_${filterLabel}`}
          variant="contained"
          onClick={() => {
            return toggleDrawer(false)
          }}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '20px;',
            padding: '4px 20px',
            fontSize: '1rem',
          }}
        >
          Listo
        </ButtonTracked>
        {filterLabel !== 'Sort' && filterLabel !== 'all_filters' && (
          <ButtonTracked
            buttonname={`filter_card_reset_${filterLabel}`}
            variant="text"
            onClick={handleResetAll}
            sx={{
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            Reiniciar todas
          </ButtonTracked>
        )}
      </div>
    </div>
  )
}

const FilterCard = function ({ filterLabel, toggleDrawer }) {
  const { filtersSelected } = useContext(AppContext)
  const isRestobarSelected = filtersSelected.MainTag?.includes(CategoriesEnum.RESTOBAR)
  if (!filterLabel) return <div />
  if (filterLabel === 'all_filters') {
    return (
      <div>
        {Object.keys(DEFAULT_FILTERS).map((label) => {
          if (!isRestobarSelected && (label === 'Ubicacion' || label === 'Consumo')) return null
          if (label === 'Mapa' || label === 'Company' || label === 'Sort') return null
          const borderBottom = label === 'BenefitType' ? 'none' : '2px solid'
          return (
            <div
              style={{ padding: '20px 0px', borderBottom, borderColor: neutral90White }}
              key={label}
            >
              <FilterCardContent filterLabel={label} key={label} />
            </div>
          )
        })}
        <div style={{ height: '45px' }} />
        <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  if (filterLabel === 'MinDiscountsAmount') {
    return (
      <div>
        <FilterCardContent filterLabel="MinDiscountsAmount" />
        <FilterCardContent filterLabel="BenefitType" />
        <div style={{ height: '45px' }} />
        <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  if (filterLabel === 'Tarjetas') {
    return (
      <div>
        <FilterCardContent filterLabel="Tarjetas" />
        <div style={{ height: '75px' }} />
        <FilterFooterFrame filterLabel="Tarjetas" toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  return (
    <div>
      <FilterCardContent filterLabel={filterLabel} />
      <div style={{ height: '45px' }} />
      <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
    </div>
  )
}

export default FilterCard
