import React from 'react'

import { Button } from '@mui/material'
import { useCookies } from 'react-cookie'
import { logEventButtonClicked, logSMEvent } from '../../events/logEvent'
import '../../App.css'
import { neutral80White, primaryPrimary } from '../../style'
import { generateDiscountTitle } from '../../helpers/others'
import { SMCookies } from '../../types/main'

const ButtonTracked = function ({ onClick, reference, ...props }: any) {
  const [smCookies] = useCookies<string>(['savemoney'])
  const cookies = smCookies as SMCookies
  const handleClick = (event: any) => {
    logEventButtonClicked(cookies.user, props.buttonname, cookies)
    if (onClick) {
      onClick(event)
    }
  }

  return <Button ref={reference} onClick={handleClick} {...props} />
}

export function PrimaryButton({
  onClick,
  buttonname,
  children,
  sx,
  disabled = false,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
  disabled?: boolean
}) {
  return (
    <ButtonTracked
      buttonname={buttonname}
      onClick={onClick}
      variant="contained"
      disableRipple={false} // enable wave effect
      disabled={disabled}
      style={{
        display: 'flex',
        minWidth: '288px',
        maxWidth: '384px',
        width: '100%',
        flex: '1',
        minHeight: '55px',
        maxHeight: '55px',
        borderRadius: 40,
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        backgroundColor: primaryPrimary,
        fontSize: '14px',
        fontWeight: 'bold',
        ...sx,
      }}
    >
      {children}
    </ButtonTracked>
  )
}

export function DiscountMapButton({
  onClick,
  url,
  buttonname,
  children,
  sx,
  reference,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  url: string
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
  reference?: React.RefObject<HTMLButtonElement>
}) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
        display: 'flex',
      }}
    >
      <ButtonTracked
        buttonname={buttonname}
        reference={reference}
        onClick={onClick}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          minWidth: 'fit-content',
          marginRight: '10px',
          borderRadius: 40,
          textTransform: 'none',
          borderColor: neutral80White,
          color: primaryPrimary,
          // backgroundColor: primaryPrimary,
          ...sx,
        }}
      >
        {children}
      </ButtonTracked>
    </a>
  )
}

export function ShareButton({
  discount,
  buttonname,
  children,
  sx,
}: {
  discount: any
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
}) {
  const handleClick = async (e: any) => {
    e.stopPropagation()
    const shareId = Math.random().toString(36).substring(2)
    const discountUrl = `www.savemoney.cl/descuentos?openDiscountId=${discount.id}&shareId=${shareId}`
    const title = generateDiscountTitle(discount)
    const text = `*${title}*
💳 Pagando con: ${discount.club}\n
👉 ${discountUrl}`

    logSMEvent('DISCOUNT_DRAWER_SHARE_BUTTON_CLICKED', {
      id: discount.id,
      url: discountUrl,
      shareId,
      buttonname,
    })
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text,
        })
      } catch (error: any) {
        logSMEvent('ERROR', {
          id: 'discount_share_error',
          buttonname,
          error: error.toString(),
          discountId: discount.id,
        })
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`
      window.open(whatsappUrl, '_blank')
    }
  }
  return (
    <PrimaryButton onClick={handleClick} buttonname={buttonname} sx={sx}>
      {children}
    </PrimaryButton>
  )
}
