import React, { useContext } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import { Button } from '@mui/material'
import { logSMEvent } from '../events/logEvent'
import { AppContext } from '../contexts/AppContext'

export const SMPrimaryButton = ({
  b,
  href,
  fullWidth,
  maxWidth,
  children,
  style,
  small,
  sx,
  ...rest
}: any) => {
  let children2 = (
    <LoadingButton
      variant="contained"
      {...rest}
      style={{
        borderRadius: 40,
        padding: small ? 10 : 20,
        height: small ? 30 : 55,
        textTransform: 'none',
        width: fullWidth && '100%',
        maxWidth: maxWidth && '384px',
        border: b && '1px solid orange',
        ...style,
        ...sx,
      }}
    >
      {children}
    </LoadingButton>
  )
  if (href) {
    children2 = (
      <a
        href={href}
        style={{
          textDecoration: 'none',
          color: 'inherit',
          backgroundColor: 'transparent',
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children2}
      </a>
    )
  }
  return children2
}

export const SMIconButton = ({ onClick, style, b, children, ...rest }: any) => {
  const handleClick = (e: any) => {
    e.preventDefault() // prevent the default action of the browser
    e.stopPropagation() // prevent the click from propagating to the parent
    onClick(e)
  }
  return (
    <IconButton
      {...rest}
      onClick={handleClick}
      style={{
        border: b && '1px solid orange',
        ...style,
      }}
    >
      {children}
    </IconButton>
  )
}

export const SMALinkButton = ({ href, eventName, children, style, sx, ...rest }: any) => {
  const { auth, user } = useContext(AppContext)
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        color: 'inherit',
        backgroundColor: 'transparent',
      }}
      {...rest}
    >
      <Button
        onClick={(e) => {
          logSMEvent(eventName, {
            rut: auth?.rut || 'none',
            user: user || 'none',
          })
          e.stopPropagation() // prevent the click from propagating to the parent
        }}
        variant="contained"
        style={{ textTransform: 'none', ...style, ...sx }}
      >
        {children}
      </Button>
    </a>
  )
}
