import './intro.css'
import '../../../App.css'
import { useNavigate } from 'react-router-dom'
import { IIntro } from './IIntro'
import { CategoryCheckBox } from '../components/CategoryCheckBox'
import { Switch } from '@mui/material'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent, stringifyMainTags } from '../../../events/logEvent'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { CategoriesEnum } from '../../../types/categories'

export const SelectCategories = function () {
  const filterLabel = 'MainTag'
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const navigate = useNavigate()
  return (
    <IIntro
      pageName="intro_main_tag"
      progressNumber={2}
      onClickBack={() => {
        logSMEvent('CLICK_BACK_IN_MAIN_TAGS')
        return navigate('/clubs')
      }}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_MAIN_TAGS', {
          mainTags: stringifyMainTags(filtersSelected.MainTag),
        })
        if (
          filtersSelected.MainTag.length === 1 &&
          filtersSelected.MainTag.includes(CategoriesEnum.RESTOBAR)
        ) {
          return navigate('/consumo')
        }
        setFiltersSelected({ ...filtersSelected, Dias: [] })
        return navigate('/descuentos')
      }}
    >
      {/* question (title): always in the top center */}
      <SMVerticalDiv sx={{ gap: 30 }}>
        <SMVerticalDiv verticalCenter horizontalCenter>
          <SMH2 center>¿Qué buscas?</SMH2>
          <SMP black>Selecciona una categoría</SMP>
          <SMHorizontalDiv sx={{ justifyContent: 'flex-end', width: '100%' }}>
            <SMP black>Ver todas</SMP>
            <Switch
              checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
              onClick={() => {
                handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
              }}
            />
          </SMHorizontalDiv>
        </SMVerticalDiv>
        <div className={'body'}>
          {filterOptions.MainTag.map((category) => {
            return <CategoryCheckBox key={category} category={category} />
          })}
        </div>
      </SMVerticalDiv>
    </IIntro>
  )
}
