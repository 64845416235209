import { OverlayView } from '@react-google-maps/api'
import {
  neutralWhite,
  primary20Black,
  primary80Black,
  primaryPrimary,
  secondary40White,
} from '../../../../style'

// const getPixelPositionOffset = (width, height) => {
//   return {
//     x: -(width / 2),
//     y: -(height / 2),
//   }
// }

export function DiscountMarker({
  star,
  discount,
  handleMarkerClick,
  isSelected,
  location,
  isApproved,
  isRemoved,
  paintBackground,
}) {
  let background = isSelected ? primaryPrimary : neutralWhite // 'rgb(140, 150, 140, 0.9)'
  // if (star) {
  //   background = isApproved ? '#19DD2C' : isRemoved ? 'red' : background
  // }
  if (paintBackground) {
    background = 'orange'
  }
  if (star && (isApproved || isRemoved)) {
    return null
  }
  return (
    <OverlayView
      position={{ lat: location.lat, lng: location.lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      // getPixelPositionOffset={getPixelPositionOffset}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          background,
          padding: '2px',
          borderRadius: '20px',
          borderColor: isSelected ? primary20Black : secondary40White,
          borderWidth: '1px',
          borderStyle: 'solid',
          cursor: 'pointer',
          zIndex: 100,
        }}
        onClick={handleMarkerClick}
      >
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isSelected ? neutralWhite : primaryPrimary,
            borderRadius: 100,
            width: 22,
            height: 22,
            marginRight: 5,
          }}
        >
          {
            categoriesIcons({
              fontSize: 10,
              color: isSelected ? primaryPrimary : neutralWhite,
            })[CategoriesEnum.RESTOBAR]
          }
        </div> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: neutralWhite,
            overflow: 'hidden',
            borderRadius: 100,
            width: 28,
            height: 28,
            marginRight: 5,
          }}
        >
          <img alt="." src={discount.logo || discount.bgimage} style={{ maxHeight: 28 }} />
        </div>
        <p
          style={{
            color: isSelected ? neutralWhite : primary80Black,
          }}
        >
          {discount.cantidadDescuento} %
        </p>
      </div>
    </OverlayView>
  )
}
