import { regiones } from '../consts/filterOptions'
import { Discount } from '../types/discount'
import dayjs from 'dayjs'

export const defaultDiscount: Discount = {
  id: '',
  categoria: '',
  club: '',
  company: '',
  companyLogo: '',
  createdAt: 0,
  deprecated: false,
  ubicacion: '',
  updatedAt: { _nanoseconds: 0, _seconds: 0 },
  XClosestLocation: null,
  XGoogleMapsLink: '',
  provider: '',
  providerLogo: 'https://static.thenounproject.com/png/3022241-200.png',
  titulo: 'titulo',
  cantidadDescuento: '',
  bgimage: 'https://i.pinimg.com/originals/19/db/31/19db31732931019b73bedcf17924f814.jpg',
  logo: '',
  descripcion: '',
  condiciones: '',
  diasNormalizados: 'L,M,W,J,V,S,D',
  fecha_hasta: dayjs().add(1, 'year').format('DD/MM/YYYY'),
  subTarjeta: '',
  direcciones: '',
  local: '',
  mainTag: 'otras',
  mainTagV2: '',
  aiQueryInput: '',
  aiQueryOutputTime: { _nanoseconds: 0, _seconds: 0 },
  gMapsOutputTime: { _nanoseconds: 0, _seconds: 0 },
  tipoConsumo: '',
  topeDescuento: '',
  tags: '',
  url: '',
  regiones: regiones,
  gMapsResults: [],
  gMapsQueries: [],
}
