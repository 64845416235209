import React, { useContext, useEffect, useState } from 'react'
import { SMBottomDrawer } from '../../../SMComponents/SMDrawer'
import { SMContent, SMFooter, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMMuiTelInput, SMTextField } from '../../../SMComponents/SMInput'
import { OpenLoginDrawerProps } from '../../../contexts/DiscountContext'
import { LoginDrawerFieldsEnum } from '../../../enums/LoginDrawerFieldsEnum'
import { request } from '../../../helpers/request'
import { useCookies } from 'react-cookie'
import { logSMEvent } from '../../../events/logEvent'
import { AppContext, UserAuthType } from '../../../contexts/AppContext'
import {
  formatRut,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  isValidRut,
} from '../../../helpers/validateForms'

export const LoginDrawer = ({
  open,
  setOpen,
  loginDrawerProps,
}: {
  open: boolean
  setOpen: (newState: any) => void
  loginDrawerProps: OpenLoginDrawerProps
}) => {
  const { auth, setAuth } = useContext(AppContext)
  const setCookie = useCookies(['secretToken', 'userId'])[1]
  // const [imForeign, setImForeign] = useState(false)
  const [name, setName] = useState(auth?.name || '')
  const [rut, setRut] = useState(auth?.rut || '')
  const [email, setEmail] = useState(auth?.email || '')
  const [phoneNumber, setPhoneNumber] = useState(auth?.phoneNumber || '+56')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // auth puede que se cargue más lento que el componente
    if (auth?.phoneNumber) setPhoneNumber(auth.phoneNumber)
    if (auth?.rut) setRut(auth.rut)
    if (auth?.email) setEmail(auth.email)
    if (auth?.name) setName(auth.name)
  }, [auth])

  const includeName = !auth?.name && loginDrawerProps.fields.includes(LoginDrawerFieldsEnum.NAME)
  const includePhoneNumber =
    !auth?.phoneNumber && loginDrawerProps.fields.includes(LoginDrawerFieldsEnum.PHONENUMBER)
  const includeEmail = !auth?.email && loginDrawerProps.fields.includes(LoginDrawerFieldsEnum.EMAIL)
  const includeRut = !auth?.rut && loginDrawerProps.fields.includes(LoginDrawerFieldsEnum.RUT)

  useEffect(() => {
    if (open) {
      logSMEvent('OPEN_LOGIN_DRAWER', {
        source: loginDrawerProps.source,
      })
    }
  }, [open])

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue)
  }

  const handleRutChange = (e: any) => {
    const rut = formatRut(e.target.value)
    setRut(rut)
  }

  const handleCreateUser = async () => {
    setIsLoading(true)
    const response = await request('create_user', {
      method: 'POST',
      body: JSON.stringify({
        name,
        phoneNumber: phoneNumber === '+56' ? '' : phoneNumber,
        rut,
        email,
      }),
    })
    setIsLoading(false)
    const user = response.data as UserAuthType
    setAuth(user)
    setCookie('secretToken', user.secretToken, {
      path: '/',
      expires: new Date(9999999999999),
    })
    setCookie('userId', user.id, {
      path: '/',
      expires: new Date(9999999999999),
    })
    logSMEvent('SUCCESS_CLOSE_LOGIN_DRAWER', {
      source: loginDrawerProps.source,
    })
    setOpen(false)
    return user
  }

  const isValidForm = () => {
    if (includeName && !isValidName()) return false
    if (includeRut && !isValidRut(rut)) return false
    if (includePhoneNumber && !isValidPhoneNumber(phoneNumber)) return false
    if (includeEmail && !isValidEmail(email)) return false
    return true
  }

  return (
    <SMBottomDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{ zIndex: 300 }}
    >
      <SMContent
        sx={{
          overflowY: 'auto', // to allow scrolling
        }}
      >
        <SMVerticalDiv>
          <SMH2>{loginDrawerProps.title}</SMH2>
          <SMP>{loginDrawerProps.subtitle}</SMP>
        </SMVerticalDiv>
        {includeName && (
          <SMTextField
            validateOnBlur={isValidName}
            textOnBlurError="Nombre y apellido inválido"
            label="Nombre y apellido"
            value={name}
            onChange={(event: any) => setName(event.target.value)}
          />
        )}
        {includeEmail && (
          <SMTextField
            validateOnBlur={isValidEmail}
            textOnBlurError="Email inválido"
            label="Email"
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
          />
        )}
        {includePhoneNumber && (
          <SMMuiTelInput
            validateOnBlur={isValidPhoneNumber}
            textOnBlurError="Número inválido"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        )}
        {includeRut && (
          <SMTextField
            validateOnBlur={isValidRut}
            textOnBlurError="Rut inválido"
            type="numeric"
            label="Rut"
            value={rut}
            onChange={handleRutChange}
            disabled={auth?.rut}
          />
        )}
      </SMContent>
      <SMFooter>
        <SMPrimaryButton
          fullWidth
          disabled={!isValidForm()}
          loading={isLoading}
          href={loginDrawerProps.href}
          onClick={async () => {
            const user = await handleCreateUser()
            loginDrawerProps.onSubmit(user)
          }}
        >
          {loginDrawerProps.buttonLabel}
        </SMPrimaryButton>
      </SMFooter>
    </SMBottomDrawer>
  )
}
