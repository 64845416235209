import React, { useContext, useEffect, useState } from 'react'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import { AppContext } from '../../../contexts/AppContext'
import { SMContent, SMVerticalDiv, SMView } from '../../../SMComponents/SMView'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { Discount } from '../../../types/discount'
import Favorites from '../../../assets/ilustrations/favorites.svg'
import { DiscountContext } from '../../../contexts/DiscountContext'

import {
  SAVE_DISCOUNTS_FIELDS_REQUIRED,
  isAuthAllowedToSaveDiscounts,
} from '../../../consts/loginRestrictions'
import { DiscountScreen } from '../components/DiscountScreen'

export const SavedDiscounts = ({
  isLoadingDiscounts,
  discounts,
}: {
  isLoadingDiscounts: boolean
  discounts: Discount[]
}) => {
  const { auth } = useContext(AppContext)
  const { handleOpenLoginDrawer } = useContext(DiscountContext)
  const [savedDiscounts, setSavedDiscounts] = useState<Discount[]>(
    discounts.filter((discount) => auth?.savedDiscounts?.includes(discount.id))
  )
  useEffect(() => {
    setSavedDiscounts(discounts.filter((discount) => auth?.savedDiscounts?.includes(discount.id)))
  }, [discounts, auth])

  if (!isAuthAllowedToSaveDiscounts(auth)) {
    return (
      <SMView includeNavBar>
        <SMContent horizontalCenter verticalCenter>
          <SMH2>Para guardar descuentos debes completar tu perfil</SMH2>
          <img src={Favorites} alt="No results" />
          <SMPrimaryButton
            fullWidth
            maxWidth
            onClick={() => {
              handleOpenLoginDrawer({
                source: 'save_discount_view_log_in_button',
                title: 'Completa tu perfil',
                subtitle:
                  'Podrás guardar descuentos y verlos en esta sección. También podrás reservar mesas en restaurantes y acceder a beneficios exclusivos.',
                fields: SAVE_DISCOUNTS_FIELDS_REQUIRED,
                buttonLabel: 'Ver descuentos guardados',
                onSubmit: () => {},
              })
            }}
          >
            Completar perfil
          </SMPrimaryButton>
        </SMContent>
      </SMView>
    )
  }
  return (
    <SMView includeNavBar>
      <SMContent>
        {savedDiscounts.length === 0 ? (
          <SMVerticalDiv horizontalCenter verticalCenter flex1>
            <SMH2 center>Guarda tus descuentos favoritos 🎉</SMH2>
            <img src={Favorites} alt="No results" />
            <SMP center>
              Utiliza el icono de guardar <BookmarkBorderRoundedIcon /> en la esquina inferior
              derecha de cada descuento.
            </SMP>
          </SMVerticalDiv>
        ) : (
          <SMVerticalDiv horizontalCenter>
            <SMH2 center>Tus descuentos guardados</SMH2>
            <div
              style={
                { marginTop: '-100px' } // para compensar el margen de DiscountScreen
              }
            >
              <DiscountScreen
                isLoadingDiscounts={isLoadingDiscounts}
                discountsToShow={savedDiscounts}
                discounts={savedDiscounts}
                search={''}
                handleChangeSearch={() => {}}
                isStar={false}
                userLevel={2}
                setNavButtonClicked={() => {}}
              />
            </div>
          </SMVerticalDiv>
        )}
      </SMContent>
    </SMView>
  )
}
