import React, { useContext, useEffect, useRef, useState } from 'react'
import { DiscountScreen } from '../discounts/components/DiscountScreen'
import 'dayjs/locale/en-gb'
import { request } from '../../helpers/request'
import { AppContext, UserRole } from '../../contexts/AppContext'
import { SMContent, SMHorizontalDiv, SMView } from '../../SMComponents/SMView'
import { SMP } from '../../SMComponents/SMText'
import { SMPrimaryButton } from '../../SMComponents/SMButton'
import { SMAppBar } from '../../SMComponents/SMAppBar'
import { Discount } from '../../types/discount'
import { defaultDiscount } from '../../helpers/deafultDiscount'
import { UnAuthorizedView } from './components/UnAuthorizedView'
import { CreateOrEditDiscountForm } from './components/CreateOrEditDiscountForm'

export const AdminProvider = () => {
  const { auth } = useContext(AppContext)
  const [isEditing, setIsEditing] = useState(false)
  const [publishedDiscounts, setPublishedDiscounts] = useState<Discount[]>([])
  const editRef = useRef(null)

  const [discountToCreate, setDiscountToCreate] = useState<Discount>(defaultDiscount)

  let INITIAL_DISCOUNT_STATE = { ...defaultDiscount, provider: auth?.entityId || '' }
  useEffect(() => {
    if (!auth) return
    request(`provider_discounts?providers=${auth.entityId}`, {}).then((response) => {
      if (response.status === 'success') {
        setPublishedDiscounts(response.data)
      }
    })
    INITIAL_DISCOUNT_STATE = { ...defaultDiscount, provider: auth?.entityId || '' }
    setDiscountToCreate(INITIAL_DISCOUNT_STATE)
  }, [auth])

  if (auth?.role !== UserRole.PROVIDER && auth?.role !== UserRole.ADMIN) {
    return <UnAuthorizedView />
  }

  const handleOnEdit = (discountToEdit: Discount) => {
    setIsEditing(true)
    setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE, ...discountToEdit })
    // scroll to Edit section
    const editCurrentRef = editRef.current as HTMLElement | null
    if (editCurrentRef) editCurrentRef.scrollIntoView({ behavior: 'smooth' })
  }

  const handleOnDelete = (discountToDelete: Discount) => {
    setPublishedDiscounts(publishedDiscounts.filter((d) => d.id !== discountToDelete.id))
    setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE })
    request(
      'admin/delete_discount',
      {
        method: 'POST',
        body: JSON.stringify({
          discountId: discountToDelete.id,
        }),
      },
      auth
    )
  }

  return (
    <SMView>
      <SMAppBar />
      <SMContent>
        <h3>Beneficios ya publicados:</h3>
        {publishedDiscounts.length === 0 && <p>No tienes descuentos publicados</p>}
        {publishedDiscounts.length > 0 && (
          <div style={{ marginTop: -100 }}>
            <DiscountScreen
              isLoadingDiscounts={false}
              discountsToShow={publishedDiscounts}
              discounts={publishedDiscounts}
              setNavButtonClicked={() => null}
              search={''}
              handleChangeSearch={() => null}
              isStar={false}
              userLevel={2}
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
            />
          </div>
        )}
        <SMHorizontalDiv>
          <h3 ref={editRef}>{isEditing ? 'Editando beneficio:' : 'Crear nuevo beneficio:'}</h3>
          {isEditing && (
            <SMPrimaryButton
              maxWidth="20px"
              onClick={() => {
                setIsEditing(false)
                setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE })
              }}
            >
              Cambiar a crear beneficio
            </SMPrimaryButton>
          )}
        </SMHorizontalDiv>
        <SMP>
          Así se verá el descuento que está
          {isEditing ? 'editando' : 'cerando'} (puede interactuar con la tarjeta haciendo click en
          ella):
        </SMP>
        <DiscountScreen
          isLoadingDiscounts={false}
          discountsToShow={[discountToCreate]}
          discounts={[discountToCreate]}
          setNavButtonClicked={() => null}
          search=""
          handleChangeSearch={() => null}
          isStar={false}
          userLevel={2}
        />
        <CreateOrEditDiscountForm
          discountToCreate={discountToCreate}
          setDiscountToCreate={setDiscountToCreate}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          publishedDiscounts={publishedDiscounts}
          setPublishedDiscounts={setPublishedDiscounts}
        />
      </SMContent>
    </SMView>
  )
}
