import { LoginDrawerFieldsEnum } from '../enums/LoginDrawerFieldsEnum'
import { UserAuthType } from '../contexts/AppContext'

export const SAVE_DISCOUNTS_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.RUT,
  LoginDrawerFieldsEnum.PHONENUMBER,
  LoginDrawerFieldsEnum.EMAIL,
]

export const isAuthAllowedToSaveDiscounts = (auth: UserAuthType | null) => {
  return auth && auth.rut && auth.phoneNumber && auth.email
}

export const RESERVE_FIELDS_REQUIRED = [
  LoginDrawerFieldsEnum.RUT,
  LoginDrawerFieldsEnum.PHONENUMBER,
  LoginDrawerFieldsEnum.EMAIL,
]

export const isAuthAllowedToReserved = (auth: UserAuthType | null) => {
  return auth && auth.rut && auth.phoneNumber && auth.email
}
