import { logEvent } from 'firebase/analytics'
import { analytics } from '../services/firebase'

import { appVersion } from '../consts/version'
import { shortRegionName } from '../consts/filterOptions'
import { CategoriesEnum } from '../types/categories'

const tarjetasAbreviation = {
  'Banco de Chile': 'BC',
  Santander: 'SAN',
  'Banco Security': 'BS',
  Scotiabank: 'SCO',
  'Banco Ripley': 'RIP',
  Movistar: 'MOV',
  'Vecino Las Condes': 'CON',
  'Mi Vitacura': 'VIT',
  'La Reina': 'REI',
  'Club Lectores El Mercurio': 'MER',
  'La Tercera': 'TER',
  RappiCard: 'RAP',
  Tenpo: 'TEN',
  Sbpay: 'SB',
  Chek: 'CHE',
  Entel: 'EN',
  'Banco Estado': 'BE',
  FullCopec: 'COP',
}

const categoriesAbreviation = {
  [CategoriesEnum.RESTOBAR]: 'RES',
  [CategoriesEnum.GAS]: 'GAS',
  [CategoriesEnum.LIQUOR]: 'LIQ',
  [CategoriesEnum.CINEMA]: 'CIN',
  [CategoriesEnum.PHARMACY]: 'FAR',
  [CategoriesEnum.CAR_RENTAL]: 'CAR',
  [CategoriesEnum.COURSES]: 'CUR',
  [CategoriesEnum.SPORTS]: 'DEP',
  [CategoriesEnum.HEALTH]: 'SAL',
  [CategoriesEnum.OTHERS]: 'OTR',
}

export const stringifyTarjetas = (tarjetas) => {
  if (!tarjetas) return ''
  return tarjetas
    .map((t) => {
      return tarjetasAbreviation[t] || t
    })
    .join(',')
}

export const stringifyMainTags = (mainTags) => {
  if (!mainTags) return ''
  return mainTags
    .map((t) => {
      return categoriesAbreviation[t] || t
    })
    .join(',')
}

export const stringifyFiltersSelected = (filtersSelected) => {
  if (!filtersSelected) return {}
  return {
    filter_benefit_type: filtersSelected.BenefitType.join(','),
    filter_consumo: filtersSelected.Consumo.join(','),
    filter_dias: filtersSelected.Dias.join(','),
    filter_main_tag: stringifyMainTags(filtersSelected.MainTag),
    filter_sort: filtersSelected.Sort.join(','),
    filter_tarjetas: stringifyTarjetas(filtersSelected.Tarjetas),
    filter_ubicacion: filtersSelected.Ubicacion.map((u) => {
      return shortRegionName[u] || u
    }).join(','),
    filter_min_discounts_amount: filtersSelected.MinDiscountsAmount?.toString(),
    filter_empresa: filtersSelected.Company?.code?.toString(),
  }
}

// const getMainProperties = (discount) => {
//   return {
//     discount_id: discount.id,
//     discount_url: discount.url,
//     discount_club: discount.club,
//     discount_main_tag: discount.mainTag,
//     discount_ubicacion: discount.ubicacionNormalizada,
//     discount_title: discount.titulo,
//     discount_dias: discount.diasNormalizados,
//   }
// }

const showLogEvents = true

export const logSMEvent = (event_name, params = {}) => {
  // console.log only if it is in local
  if (process.env.NODE_ENV === 'development' && showLogEvents) {
    console.log('[SMEvent] ' + event_name, params)
  }
  logEvent(analytics, event_name, {
    ...params,
    app_version: appVersion,
    env: process.env.NODE_ENV,
  })
}

export const logEventPageVisited = (user, pageName, searchText = '') => {
  logSMEvent('PAGE_VISITED', {
    user,
    app_version: appVersion,
    page_name: pageName,
    search_text: searchText,
  })
}

export const logEventButtonClicked = (user, buttonname, cookies) => {
  const filtersSelected = stringifyFiltersSelected(cookies.filtersSelected)
  logSMEvent('BUTTON_CLICKED', {
    user,
    app_version: appVersion,
    button_name: buttonname,
    ...filtersSelected,
  })
}

// TODO:
// export const logEventSearchText = (user, searchText, amountOfResults) => {
//   analytics.logEvent('w_search_text', {
//     user,
//     app_version: appVersion,
//     search_text: searchText,
//     amount_of_results: amountOfResults,
//   })
// }
