import React, { useState, useContext } from 'react'
// import { DownloadAppBanner } from '../../common/DownloadAppBanner'

import '../../../App.css'
import { AppContext } from '../../../contexts/AppContext'
import SwipeableEdgeDrawer from '../components/swipeableDrawer/SwipeableDrawer'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded'
import { ReactComponent as MapLocationDotSolid } from '../../../assets/icons/MapLocationDotSolid.svg'
import { DiscountPage } from './DiscountPage'
import { logSMEvent } from '../../../events/logEvent'
import { LoginDrawer } from '../components/LoginDrawer'
import {
  DiscountContext,
  OpenLoginDrawerProps,
  OpenReportErrorProps,
  OpenReservationProps,
  defaultLoginDrawerProps,
  defaultReportErrorDrawerProps,
  defaultReservationDrawerProps,
} from '../../../contexts/DiscountContext'
import { ReservationDrawer } from '../components/ReservationDrawer'
import { ReportErrorDrawer } from '../components/ReportErrorDrawer'

export const Discount = function ({
  star,
  isStar,
  isLoadingDiscounts,
  discounts,
  discountsSortedByDcto,
  discountsSortedByKms,
}: {
  star: any
  isStar: boolean
  isLoadingDiscounts: boolean
  discounts: any
  discountsSortedByDcto: any
  discountsSortedByKms: any
}) {
  const { filtersSelected } = useContext(AppContext)

  // state for discountContext
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false)
  const [loginDrawerProps, setLoginDrawerProps] = useState(defaultLoginDrawerProps)

  const [openReportErrorDrawer, setOpenReportErrorDrawer] = useState(false)
  const [reportErrorDrawerProps, setReportErrorDrawerProps] = useState(
    defaultReportErrorDrawerProps
  )

  const [openReservationDrawer, setOpenReservationDrawer] = useState(false)
  const [reservationDrawerProps, setReservationDrawerProps] = useState(
    defaultReservationDrawerProps
  )

  const [navButtonClicked, setNavButtonClicked] = useState(false)
  const [page, setPage] = useState('list')

  const discountContextValue = {
    openLoginDrawer,
    handleOpenLoginDrawer: (props: OpenLoginDrawerProps) => {
      setLoginDrawerProps(props)
      setOpenLoginDrawer(true)
    },
    openReportErrorDrawer,
    handleOpenReportErrorDrawer: (props: OpenReportErrorProps) => {
      setReportErrorDrawerProps(props)
      setOpenReportErrorDrawer(true)
    },
    openReservationDrawer,
    handleOpenReservationDrawer: (props: OpenReservationProps) => {
      setReservationDrawerProps(props)
      setOpenReservationDrawer(true)
    },
  }

  return (
    <DiscountContext.Provider value={discountContextValue}>
      <DiscountPage
        star={star}
        isStar={isStar}
        isLoadingDiscounts={isLoadingDiscounts}
        discounts={discounts}
        discountsSortedByDcto={discountsSortedByDcto}
        discountsSortedByKms={discountsSortedByKms}
        page={page}
        setNavButtonClicked={setNavButtonClicked}
      />
      <BottomNavigation
        sx={{
          width: '100%',
          height: '70px',
          zIndex: 10,
          position: 'fixed',
          bottom: 0,
          boxShadow: '0px -3px 5px 0px rgba(0,0,0,0.1)',
        }}
        value={page}
        onChange={(event, newValue) => {
          logSMEvent('CHANGE_DISCOUNT_PAGE', { page: newValue })
          setPage(newValue)
        }}
      >
        <BottomNavigationAction value="list" icon={<DiscountRoundedIcon />} />
        <BottomNavigationAction
          value="map"
          icon={<MapLocationDotSolid fill={page === 'map' ? '#3874CB' : '#666666'} />}
        />
        <BottomNavigationAction value="savedDiscounts" icon={<BookmarkRoundedIcon />} />
        <BottomNavigationAction value="account" icon={<PersonRoundedIcon />} />
      </BottomNavigation>
      <SwipeableEdgeDrawer
        navButtonClicked={navButtonClicked}
        setNavButtonClicked={setNavButtonClicked}
        filtersSelected={filtersSelected}
      />
      <LoginDrawer
        open={openLoginDrawer}
        setOpen={setOpenLoginDrawer}
        loginDrawerProps={loginDrawerProps}
      />
      <ReportErrorDrawer
        open={openReportErrorDrawer}
        setOpen={setOpenReportErrorDrawer}
        reportErrorDrawerProps={reportErrorDrawerProps}
      />
      <ReservationDrawer
        open={openReservationDrawer}
        setOpen={setOpenReservationDrawer}
        reservationDrawerProps={reservationDrawerProps}
      />
      {/* <DownloadAppBanner showAppBanner={showAppBanner} setShowAppBanner={setShowAppBanner} /> */}
    </DiscountContext.Provider>
  )
}
