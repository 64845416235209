import DiscountsHolder from './DiscountsHolder'
import { SuggestedBottoms } from './SuggestedBottoms'
import { DiscountDrawer } from './DiscountDrawer'
import React, { useEffect, useState } from 'react'
import NoDiscountsFound from './NoDiscountsFound'
import { LoadingDiscounts, LoadingDiscountsWithDiscountSelected } from './LoadingDiscounts'
import { getUrlParam, setUrlParam } from '../../../helpers/others'
import { Discount } from '../../../types/discount'
import { SMH2 } from '../../../SMComponents/SMText'

export const DiscountScreen = function ({
  isLoadingDiscounts,
  discountsToShow,
  discounts,
  search,
  handleChangeSearch,
  isStar,
  setNavButtonClicked,
  userLevel,
  onDelete,
  onEdit,
}: {
  isLoadingDiscounts: boolean
  discountsToShow: Discount[]
  discounts: Discount[]
  search: string
  handleChangeSearch: (search: string) => void
  isStar: boolean
  setNavButtonClicked: (name: string) => void
  userLevel?: number
  onDelete?: (discount: Discount) => void
  onEdit?: (discount: Discount) => void
}) {
  const urlOpenDiscountId = getUrlParam('openDiscountId')
  const [discountSelectedInList, setDiscountSelectedInList] = useState<Discount | null | undefined>(
    null
  )

  useEffect(() => {
    if (urlOpenDiscountId) {
      const preOpenedDiscountByUrl = discounts.find(
        (discount: Discount) => discount.id === urlOpenDiscountId
      )
      setDiscountSelectedInList(preOpenedDiscountByUrl)
    }
  }, [urlOpenDiscountId, discounts])

  const handleDiscountClick = (discount: Discount | null) => {
    setUrlParam('openDiscountId', discount?.id)
    if (process.env.NODE_ENV !== 'production') {
      console.log(discount)
    }
    setDiscountSelectedInList(discount)
  }

  if (isLoadingDiscounts && urlOpenDiscountId) return <LoadingDiscountsWithDiscountSelected />
  if (isLoadingDiscounts) return <LoadingDiscounts />
  if (discountsToShow.length === 0 && !discountSelectedInList) {
    return (
      <NoDiscountsFound
        search={search}
        handleChangeSearch={handleChangeSearch}
        setNavButtonClicked={setNavButtonClicked}
        isStar={isStar}
      />
    )
  }
  return (
    <>
      <DiscountDrawer
        discount={discountSelectedInList || null}
        isStar={isStar}
        handleDiscountClick={handleDiscountClick}
      />
      <div style={{ marginTop: '100px' }}>
        {userLevel !== 2 && (
          <>
            <SMH2 center style={{ margin: '12px' }}>
              Tenemos {discountsToShow.length} beneficios para ti
            </SMH2>
            <SuggestedBottoms setNavButtonClicked={setNavButtonClicked} isStar={isStar} />
          </>
        )}
        <DiscountsHolder
          discountsToShow={discountsToShow}
          isStar={isStar}
          handleDiscountClick={handleDiscountClick}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </div>
    </>
  )
}
