import React from 'react'
import { CircularProgress, Skeleton, Stack } from '@mui/material'

import styles from './discountsHolder.module.css'
import { DISCOUNT_DRAWER_BODY_WIDTH } from './DiscountDrawer'

const { discountAndDescriptionFrame, discountsHolder, discountCardSkeleton } = styles

export const LoadingDiscounts = function () {
  return (
    <div style={{ marginTop: '100px' }} className={discountAndDescriptionFrame}>
      <CircularProgress style={{ position: 'absolute', top: '250px' }} />
      <div className={discountsHolder}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
          return (
            <Stack
              spacing={1}
              className={discountCardSkeleton}
              style={{ boxShadow: '0px 0px 0px 0px' }}
              key={x}
            >
              <Skeleton
                variant="rectangular"
                height={150}
                style={{ borderRadius: '10px 10px 5px 5px' }}
              />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px' }} />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px' }} />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px 5px 10px 10px' }} />
            </Stack>
          )
        })}
      </div>
    </div>
  )
}

export const LoadingDiscountsWithDiscountSelected = function () {
  return (
    <div style={{ marginTop: '100px' }} className={discountAndDescriptionFrame}>
      <CircularProgress style={{ position: 'absolute', top: '250px', zIndex: 101 }} />
      <Stack
        spacing={0}
        style={{
          position: 'absolute',
          height: '100vh',
          right: '0px',
          top: '0px',
          zIndex: 100,
          width: DISCOUNT_DRAWER_BODY_WIDTH,
          backgroundColor: 'white',
        }}
      >
        <Skeleton variant="rectangular" height="180px" width="100%" />
        <Skeleton
          variant="rectangular"
          height="110px"
          width="110px"
          style={{
            zIndex: 101,
            position: 'absolute',
            top: '120px',
            left: '30px',
            bottom: '100px',
            borderRadius: '10px',
          }}
        />
        <Skeleton
          variant="rectangular"
          height="110px"
          width="110px"
          style={{
            zIndex: 101,
            position: 'absolute',
            top: '120px',
            left: '170px',
            bottom: '100px',
            borderRadius: '10px',
          }}
        />
        <Stack spacing={1} style={{ height: 'calc(100% - 300px)', marginLeft: 10 }}>
          <div style={{ height: 50 }}></div>
          <Skeleton variant="text" height="30px" width="200px" />
          <Skeleton variant="text" height="30px" width="250px" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="200px" />
          <Skeleton variant="text" height="30px" width="250px" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
          <Skeleton variant="text" height="30px" width="80%" />
        </Stack>
      </Stack>
      <div className={discountsHolder}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => {
          return (
            <Stack
              spacing={1}
              className={discountCardSkeleton}
              style={{ boxShadow: '0px 0px 0px 0px' }}
              key={x}
            >
              <Skeleton
                variant="rectangular"
                height={150}
                style={{ borderRadius: '10px 10px 5px 5px' }}
              />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px' }} />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px' }} />
              <Skeleton variant="rectangular" style={{ borderRadius: '5px 5px 10px 10px' }} />
            </Stack>
          )
        })}
      </div>
    </div>
  )
}
