import './intro.css'
import '../../../App.css'
import { useNavigate } from 'react-router-dom'
import { IIntro } from './IIntro'
import { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent, stringifyMainTags } from '../../../events/logEvent'
import { ConsumoCheckBox } from '../components/ConsumoCheckBox'
import { Switch } from '@mui/material'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { SMH2 } from '../../../SMComponents/SMText'
import { useFilterOptions } from '../../../hooks/filterOptions'

const filterLabel = 'Consumo'
export const SelectConsumo = function () {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  const navigate = useNavigate()
  return (
    <IIntro
      pageName="intro_consumo"
      progressNumber={2}
      onClickBack={() => {
        logSMEvent('CLICK_BACK_IN_CONSUMO')
        return navigate('/categories')
      }}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_CONSUMO', {
          consumo: stringifyMainTags(filtersSelected.Consumo),
        })
        return navigate('/days')
      }}
    >
      {/* question (title): always in the top center */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <SMH2>¿Presencial o Delivery?</SMH2>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <p>Ambas</p>
          <Switch
            checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
            onClick={() => {
              handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
            }}
          />
        </div>
      </div>
      <div className={'body'}>
        {filterOptions.Consumo.map((consumo) => {
          return <ConsumoCheckBox key={consumo} option={consumo} />
        })}
      </div>
    </IIntro>
  )
}
