// Este comentario me imagino que es un error xq si esta en las dependencias
import { InView } from 'react-intersection-observer'
import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@mui/material'

import styles from './discountsHolder.module.css'
import DiscountCard from './DiscountCard'

// const isProd = process.env.NODE_ENV === 'production'

const { discountAndDescriptionFrame, discountsHolder, finishDot } = styles
const discountsToRenderEachLoading = 12 // (3x4) so it can display nice in any screen

const DiscountsHolder = function ({
  discountsToShow,
  isStar,
  handleDiscountClick,
  onDelete,
  onEdit,
}) {
  const [discountsToRender, setDiscountsToRender] = useState(discountsToRenderEachLoading)
  const [track, setTrack] = useState(true)
  const [showLoading, setShowLoading] = useState(true)

  const handleVisibility = (isVisible) => {
    if (isVisible) {
      setTrack(false)
      setDiscountsToRender(discountsToRender + discountsToRenderEachLoading)
      setTimeout(() => {
        // we wait some time before seting up the new tracker
        // we only set a tracker if we have not finish showing all discounts (same as showLoading)
        if (showLoading) {
          setTrack(true)
        }
      }, 1500)
      clearTimeout()
    }
  }

  useEffect(() => {
    setTrack(discountsToShow?.length > discountsToRenderEachLoading)
    setShowLoading(discountsToShow?.length > discountsToRenderEachLoading)
    setDiscountsToRender(discountsToRenderEachLoading)
  }, [discountsToShow])

  useEffect(() => {
    if (discountsToRender >= discountsToShow?.length) {
      setTrack(false)
      setShowLoading(false)
    }
  }, [discountsToRender])

  if (!discountsToShow) {
    return (
      <p style={{ textAlign: 'center' }}>:( Buuh hubo un error yendo a buscar los beneficios</p>
    )
  }

  return (
    <div className={discountAndDescriptionFrame}>
      <div className={discountsHolder}>
        {discountsToShow?.slice(0, discountsToRender).map((discount) => {
          return (
            <DiscountCard
              discount={discount}
              isStar={isStar}
              key={discount.id}
              handleDiscountClick={handleDiscountClick}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          )
        })}
      </div>
      {track && (
        <InView onChange={handleVisibility} threshold={0.5}>
          <div style={{ color: 'white', fontSize: '5px' }}>Tracker</div>
        </InView>
      )}
      {showLoading ? (
        <CircularProgress style={{ marginBottom: '100px', marginTop: '20px' }} />
      ) : (
        <div className={finishDot} />
      )}
    </div>
  )
}

export default DiscountsHolder
