import React from 'react'

const navBarHeight = 72
const headerHeight = 96

export const SMView = ({
  horizontalCenter,
  verticalCenter,
  includeNavBar,
  includeSearchBar,
  sx,
  b,
  style,
  children,
  ...rest
}: any) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        flex: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: b && '3px solid red',
        marginTop: includeSearchBar ? headerHeight : 0,
        justifyContent: verticalCenter && 'center',
        alignItems: horizontalCenter && 'center',
        flexDirection: 'column',
        ...sx,
        ...style,
      }}
    >
      {children}
      {includeNavBar && <div style={{ display: 'flex', minHeight: navBarHeight }} />}
    </div>
  )
}

export const SMWebViewContent = ({ sx, style, children, ...rest }: any) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        // paddingVertical: '5%',
        padding: 80,
        paddingLeft: '15%',
        paddingRight: '15%',
        gap: 40,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const SMContent = ({
  horizontalCenter,
  verticalCenter,
  sx,
  style,
  children,
  b,
  ...rest
}: any) => {
  return (
    <div
      {...rest}
      style={{
        border: b && '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: verticalCenter && 'center',
        alignItems: horizontalCenter && 'center',
        textAlign: horizontalCenter && 'center',
        flex: 1,
        padding: 16,
        gap: 24,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const SMFooter = ({ horizontalCenter, sx, style, children, ...rest }: any) => {
  // se va a ir para abajo, xq SMContent tiene flex: 1
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: horizontalCenter && 'center',
        padding: 16,
        gap: 24,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const SMVerticalDiv = ({
  horizontalCenter,
  verticalCenter,
  fullWidth,
  sx,
  style,
  children,
  flex1,
  b,
  ...rest
}: any) => {
  return (
    <div
      {...rest}
      style={{
        border: b && '1px solid blue',
        display: 'flex',
        flexDirection: 'column',
        alignItems: horizontalCenter && 'center',
        justifyContent: verticalCenter && 'center',
        width: fullWidth && '100%',
        gap: 12,
        flex: flex1 && 1,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export const SMHorizontalDiv = ({ maxSpaceBetween, sx, b, style, children, ...rest }: any) => {
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        border: b && '1px solid green',
        flexDirection: 'row',
        alignItems: 'center', // vertical center
        justifyContent: maxSpaceBetween ? 'space-between' : 'flex-start', // horizontal align
        gap: 12,
        ...sx,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
