function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const aproxDistanceMultipler = 1.3 // cuando se hace este calculo es línea completamente recta
  const d = R * c * aproxDistanceMultipler // Distance in km
  return d
}

const getClosestLocation = (locations, myLatLon) => {
  /*
  params: locations = [{lat, lng, placeId}, ...]
          myLatLon = {latitude, longitude}
  returns: {lat, lng, distance, placeId}
  */
  if (!locations || !myLatLon || locations.length === 0 || !myLatLon.latitude) return null
  const { latitude, longitude } = myLatLon
  let closestLocation = { lat: 0, lng: 0, distance: 999999999999, placeId: '' }
  locations.forEach((l) => {
    const newDistance = getDistanceFromLatLonInKm(l.lat, l.lng, latitude, longitude)
    if (newDistance < closestLocation.distance) {
      closestLocation = {
        lat: l.lat,
        lng: l.lng,
        distance: newDistance,
        placeId: l.placeId,
      }
    }
  })
  return closestLocation
}

const showTwoDecimals = (kms) => {
  kms = Math.round(kms * 10) / 10
  return kms
}

export { getClosestLocation, showTwoDecimals }
