import { primary40White, primaryPrimary, neutral90White } from '../../../style'
import './progressBar.css'

export const ProgressBar = function ({ progressNumber }) {
  const progressBars = []
  for (let i = 1; i < 4; i += 1) {
    let backgroundColor = neutral90White
    if (i < progressNumber) {
      backgroundColor = primary40White
    } else if (i === progressNumber) {
      backgroundColor = primaryPrimary
    }
    progressBars.push(<div className="eachProgressBar" key={i} style={{ backgroundColor }} />)
  }
  return (
    <div className="progressBarFrame">
      <div style={{ display: 'flex' }}>{progressBars}</div>
    </div>
  )
}
