import React, { useContext, useState } from 'react'
import { Box, Chip, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { Discount } from '../../../types/discount'
import { logSMEvent } from '../../../events/logEvent'
import { request } from '../../../helpers/request'
import { AppContext } from '../../../contexts/AppContext'
import { ALL_CATEGORIES, regiones } from '../../../consts/filterOptions'
import { PrimaryButton } from '../../common/SMButton'
import { defaultDiscount } from '../../../helpers/deafultDiscount'
import dayjs from 'dayjs'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { SMP } from '../../../SMComponents/SMText'
import { SMTextField } from '../../../SMComponents/SMInput'

export const CreateOrEditDiscountForm = ({
  discountToCreate,
  setDiscountToCreate,
  isEditing,
  setIsEditing,
  publishedDiscounts,
  setPublishedDiscounts,
}: {
  discountToCreate: Discount
  setDiscountToCreate: (discount: Discount) => void
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  publishedDiscounts: Discount[]
  setPublishedDiscounts: (discounts: Discount[]) => void
}) => {
  const { auth } = useContext(AppContext)

  const [loadingBgImage, setLoadingBgImage] = useState(false)
  const [loadingLogoImage, setLoadingLogoImage] = useState(false)

  return (
    <SMVerticalDiv>
      {discountToCreate.id && <SMP>Editando el descuento: {discountToCreate.id}</SMP>}
      <SMP black class="subtitle-1">
        Todos los campos son opcionales
      </SMP>
      <SMTextField
        value={discountToCreate.titulo}
        placeholder="Titulo"
        sx={{ flex: 1, backgroundColor: 'white' }}
        onChange={(e: any) => setDiscountToCreate({ ...discountToCreate, titulo: e.target.value })}
      />
      <SMTextField
        value={discountToCreate.descripcion}
        placeholder="Descripción"
        sx={{ backgroundColor: 'white', flex: 1 }}
        multiline
        onChange={(e: any) =>
          setDiscountToCreate({ ...discountToCreate, descripcion: e.target.value })
        }
      />
      <SMTextField
        value={discountToCreate.condiciones}
        placeholder="Condiciones"
        onChange={(e: any) =>
          setDiscountToCreate({ ...discountToCreate, condiciones: e.target.value })
        }
        sx={{ backgroundColor: 'white', flex: 1 }}
        multiline
      />

      <SMHorizontalDiv>
        <SMTextField
          value={discountToCreate.cantidadDescuento}
          placeholder="Cantidad del descuento en porcentaje si aplica"
          sx={{ backgroundColor: 'white', width: 200 }}
          onChange={(e: any) =>
            setDiscountToCreate({ ...discountToCreate, cantidadDescuento: e.target.value })
          }
        />
        <SMTextField
          value={discountToCreate.topeDescuento}
          placeholder="Tope máximo de descuento"
          onChange={(e: any) =>
            setDiscountToCreate({ ...discountToCreate, topeDescuento: e.target.value })
          }
          sx={{ backgroundColor: 'white', width: 300 }}
          multiline
        />
      </SMHorizontalDiv>
      <SMVerticalDiv>
        <SMHorizontalDiv>
          <SMP black>Logo empresa</SMP>
          {discountToCreate.companyLogo && (
            <img
              src={discountToCreate.companyLogo}
              alt="logo"
              style={{ maxWidth: 80, height: 20, margin: '0px 10px' }}
            />
          )}
          {loadingLogoImage && (
            <p style={{ color: 'green', margin: '0px 10px' }}>Subiendo imagen...</p>
          )}
          <input
            type="file"
            onChange={async (e: any) => {
              setLoadingLogoImage(true)
              const imageFile = e.target.files[0]
              const base64: any = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(imageFile)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
              })
              const base64Clean = base64.split(',')[1]
              try {
                const res = await request(
                  'admin/upload_image',
                  {
                    method: 'POST',
                    body: JSON.stringify({
                      fileName: imageFile.name,
                      image: base64Clean,
                    }),
                  },
                  auth
                )
                logSMEvent('IMAGE_UPLOAD', { company: discountToCreate?.company })
                if (res.status === 'success')
                  setDiscountToCreate({ ...discountToCreate, companyLogo: res.data.imageUrl })
                else console.log('error 1', res)
              } catch (error) {
                console.log('error', error)
              }
              setLoadingLogoImage(false)
            }}
          />
        </SMHorizontalDiv>
        <SMHorizontalDiv>
          <SMP black>Imagen de fondo</SMP>
          {discountToCreate.bgimage && (
            <img
              src={discountToCreate.bgimage}
              alt="logo"
              style={{ maxWidth: 80, height: 20, margin: '0px 10px' }}
            />
          )}
          {loadingBgImage && (
            <p style={{ color: 'green', margin: '0px 10px' }}>Subiendo imagen...</p>
          )}
          <input
            type="file"
            onChange={async (e: any) => {
              setLoadingBgImage(true)
              const imageFile = e.target.files[0]
              const base64: any = await new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(imageFile)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
              })
              const base64Clean = base64.split(',')[1]
              try {
                const res = await request(
                  'admin/upload_image',
                  {
                    method: 'POST',
                    body: JSON.stringify({
                      fileName: imageFile.name,
                      image: base64Clean,
                    }),
                  },
                  auth
                )
                logSMEvent('IMAGE_UPLOAD', { company: discountToCreate?.company })
                if (res.status === 'success')
                  setDiscountToCreate({ ...discountToCreate, bgimage: res.data.imageUrl })
                else console.log('error 1', res)
              } catch (error) {
                console.log('error', error)
              }
              setLoadingBgImage(false)
            }}
          />
        </SMHorizontalDiv>
      </SMVerticalDiv>
      <SMHorizontalDiv>
        <p>Fecha expiración del beneficio</p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
          <DatePicker
            sx={{ flex: 1 }}
            value={dayjs(discountToCreate.fecha_hasta, 'DD/MM/YYYY')}
            onChange={(date) => {
              const newDateInput = dayjs(date).format('DD/MM/YYYY')
              if (newDateInput === 'Invalid Date')
                setDiscountToCreate({ ...discountToCreate, fecha_hasta: '' })
              else setDiscountToCreate({ ...discountToCreate, fecha_hasta: newDateInput })
            }}
          />
        </LocalizationProvider>
      </SMHorizontalDiv>
      <SMHorizontalDiv>
        <p>Dias que el beneficio aplica</p>
        <FormControl sx={{ flex: 1 }}>
          <Select
            multiple
            value={
              discountToCreate.diasNormalizados ? discountToCreate.diasNormalizados.split(',') : []
            }
            onChange={(e: any) => {
              const newDays = e.target.value || []
              setDiscountToCreate({ ...discountToCreate, diasNormalizados: newDays.join(',') })
            }}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {['L', 'M', 'W', 'J', 'V', 'S', 'D'].map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SMHorizontalDiv>
      <p>Categorias</p>
      <FormControl>
        <Select
          multiple
          value={discountToCreate.mainTag ? discountToCreate.mainTag.split(',') : []}
          onChange={(e: any) => {
            const newCategories = e.target.value || []
            setDiscountToCreate({ ...discountToCreate, mainTag: newCategories.join(',') })
          }}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {ALL_CATEGORIES.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <p>Regiones:</p>
      <FormControl>
        <Select
          multiple
          value={discountToCreate.regiones}
          onChange={(e: any) => {
            const {
              target: { value },
            } = e
            const newRegiones = typeof value === 'string' ? value.split(',') : value
            setDiscountToCreate({ ...discountToCreate, regiones: newRegiones })
          }}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {regiones.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <SMHorizontalDiv>
        <SMP>Url para redirecciona a los usuarios:</SMP>
        <SMTextField
          value={discountToCreate.url}
          placeholder="Url para redireccionar a los usuarios"
          onChange={(e: any) => setDiscountToCreate({ ...discountToCreate, url: e.target.value })}
          sx={{ backgroundColor: 'white', flex: 1 }}
          multiline
        />
      </SMHorizontalDiv>
      {/* <h4>Solo rellenar si es un restaurante, bar o café:</h4>
      <div style={{ display: 'flex' }}>
        <div>
          <input
            type="text"
            value={discountToCreate.local}
            placeholder="Nombre del local"
            onChange={(e) => setDiscountToCreate({ ...discountToCreate, local: e.target.value })}
          />
          <input
            type="text"
            value={discountToCreate.direcciones}
            placeholder="Direcciones"
            onChange={(e) =>
              setDiscountToCreate({ ...discountToCreate, direcciones: e.target.value })
            }
          />
        </div>
        <div>
          <p>Presencial/delivery</p>
          <FormControl sx={{ m: 1, width: 250 }}>
            <Select
              multiple
              value={discountToCreate.tipoConsumo ? discountToCreate.tipoConsumo.split(',') : []}
              onChange={(e) => {
                const newTipoConsumo = e.target.value || []
                setDiscountToCreate({ ...discountToCreate, tipoConsumo: newTipoConsumo.join(',') })
              }}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {['presencial', 'delivery'].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div> */}
      <PrimaryButton
        buttonname=""
        sx={{ marginTop: 20 }}
        onClick={() => {
          const publishedDiscountsNewList = publishedDiscounts.filter(
            (d) => d.id !== discountToCreate.id
          )
          setPublishedDiscounts([...publishedDiscountsNewList, discountToCreate])
          setDiscountToCreate({ ...defaultDiscount, company: auth?.entityId || '' })
          setIsEditing(false)
          request(
            'admin/create_edit_discount',
            {
              method: 'POST',
              body: JSON.stringify({
                discount: discountToCreate,
              }),
            },
            auth
          )
        }}
      >
        {isEditing ? 'Subir edición' : 'Publicar descuento'}
      </PrimaryButton>
    </SMVerticalDiv>
  )
}
