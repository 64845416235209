import React from 'react'
import { neutral90White, neutralWhite } from '../style'

export const SMCard = ({ style, b, children, ...rest }: any) => {
  return (
    <div
      {...rest}
      style={{
        border: b && '1px solid orange',
        backgroundColor: neutralWhite,
        padding: 20,
        // shadow
        boxShadow: `4px 4px 4px ${neutral90White}`,
        borderRadius: 16,
        width: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  )
}
