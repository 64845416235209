export const appVersion = '5.1.5'

// 5.1.5 Circular progress loading discounts was hidden
// 5.1.4 Agregar Lider Bci
// 5.1.3 Agregar ubicación para que se guarde en las cookies y no se cambie cada vez que se renderee el mapa
// 5.1.2 Agregar claro action + onlyReserved muestra los wa.me link tmbn
// 5.1.1 Fix bug con regular expressions
// 5.1.0 Agregar version a los logs + star options en drawer + reservation drawer flexible (wa.me y link a restaurante)
// 5.0.6 Ahora para guardar un descuento se necesita poner email + Botón y Drawer de reserva
// 5.0.5 Sacar Empleo de filterCard Convenios y borrar archivo
// 5.0.4 Cambiar la forma en que se genera el titulo y confiar 100% en la I.A
// 5.0.3 Sacar filtros mapa y empleo. Agregar botón > 30%.
// 4.5.1 Agrreglar bug de xq se va a blanco (expresion regular)
// 4.5.0 Sacar las empresas request/forms, clubs vienen del backend (filterOptions), sacar forms para "agregar clubs/empresa/categoria...", ErrorBoundary
// 4.7.4 Ahora los clubes vienen de la bbdd
// 4.7.3 Arreglar bug de updatedAt. Al seleccionar una empresa se caía.
// 4.7.1 Agregar meta tag para google ads
// 4.6.9 Agregar boton para sacar franquicias en mapa
// 4.6.8 Arreglar última actualización. Solo presencial en el mapa. Hide en Star
// 4.6.7 Cambios en isStar
// 4.6.4 Arreglo de "bug", cuando en el discount no hay atributo direcciones ni gMapsResults
// 4.6.3 Solo mostrar restobars en el mapa
// 4.6.2 Sacar beneficios de Claro
// 4.6.1 Agregar beneficios de Claro
// 4.6.0 Agregar "Ultima actualización"
// 4.5.9 Cambiar logo Full Copec
// 4.5.8 Link a bbdd desde reportar error + cambiar estado de un error
// 4.5.7 Agregar Banner
// 4.5.6 Subir Banco Estado y Caja Los Andes
// 4.5.5 Agregar Call to action de copec
// 4.5.4 Agregamos Full Copec
// 4.5.3 No mostrar todas las direcciones de 1 y advertir al usuario que deben revisar antes de ir.
// 4.5.2 Hacer discountError page + change props in reportError
// 4.5.1 Agregar "Reportar un error" en la card chica
// 4.5.0 Agregar "Reportar un error" en el card drawer
// 4.4.9 cambiar mails de wayoalamos a nicolas@savemoney.cl
// 4.4.8 Fix style of términos y condiciones
// 4.4.7 Agregar términos y condiciones
// 4.4.6 modal para publicar un descuento propio
// 4.4.5 sacar phone category y agregar (hoy)
// 4.4.4 Agregar form para solicitar subir un club, empresa y categoria
// 4.4.3 Mejorar admin/company
// 4.4.2 Cambiar companyForm fields para obtener datos de RRHH. Agregar y cambiar mi mail tmbn.
// 4.4.1 Panel admin para empresas
// 4.4.0 Cambiar admin panel + sort button + size of card + card address see more
// 4.3.9 Cambiar login drawer description sin prometer lo de los likes/dislikes y comentarios.
// 4.3.8 Agregar botón para abrir tarjeta tapp
// 4.3.7 Si no escoge restaurante, entonces se marcan todos los dias y salta directo a los descuentos
// 4.3.6 agregar validacion a los forms
// 4.3.5 add bg loading image to discount drawer
// 4.3.4 hacer bien formateado el rut con . y -
// 4.3.3 Arreglar error al abrir un descuneto.
// 4.3.2 Sacar filtro de franquicias xq deja la cagada en el listado de descuentos. Queda pendiente arreglar
// 4.3.1 Arreglar bug con los filtros
// 4.3.0 Agregar ts, vista de profile y guardar en favoritos
// 4.2.3 Updates to ComapaniesRequest page
// 4.2.2 Agregar UTM a urls y Agregar valores default a create_discount
// 4.2.1 Subir archivo foto en create_discount
// 4.2.1 Agregar la categoria planes y telefonos
// 4.2.0 Agregar create_discount panel
// 4.1.8 Hacer pregunta por presencial/delivery + fix del filtro consumo/regiones para solo filtrar restaurantes
// 4.1.7 Toggle all tarjetas
// 4.1.6 Logear geolocation
// 4.1.5 Agregar log de company in click next in club
// 4.1.4 Cambiar ordenar por "Relevancia" a "Más vistos" + eliminar el orden previo que estaba haciendo el frontend
// 4.1.3 Que se guarde el userId con cada visualizacion
// 4.1.2 Agregar visualizaciones + agregar logSMEvent para errores de location y error de fetch
// 4.1.1 Remove gLocation +  add logger: CLOSE_DRAWER, CHANGE_DISCOUNT_PAGE, CLICK_BACK_IN_..., CLICK_NEXT_IN_... + Arreglar tipoConsumo + agregar buscador de empresa
// 4.1.0 Arreglar navigator.share, scar url
// 4.0.9 Agregar spinner en discountSelected skeleton loading
// 4.0.8 Mejora de algunos logs + hacer que openDiscountId funcione siempre + agregar skeleton para openDiscount + share button!
// 4.0.7 Add follow us ig + sacar providers en company lists + arreglar logs a mayuscula utilizando logSMEvent + persist search y open discount in url + Ver descuentos "de hoy"
// 4.0.6 cambiar companyForm
// 4.0.5 solo probando si se va a blanco en prod
// 4.0.4 Añadir logs + hacer que no se vaya a blanco al subir a prod
// 4.0.3 card with pointer  + rrhh form es obligatorio
// 4.0.2 add company_requests + mercer + language change + style fix + gPhotos changed + remove expired discount
// 4.0.1 fix small bug with legacy region in cookies
// 4.0.0 Add support for gMapsResultsV2 in map + una sola categoria a la vez + sacar feedback + navbar tus favoritos + add drawer
// 3.2.9 Add dependencies (pending and analyzed) + ...
// 3.2.8 Tratar de arreglar bug, cambiando el cookie provider y el query (cache) provider
// 3.2.7 Agregar Tapp + margen en filtersFrame y Ordenar button + AppWrapper/App
// 3.2.6 Agregar seguna pagina en companyForm para que agreguen su nombre, mail, y email de rrhh
// 3.2.5 sacar los comentarios en companyForm y agregar logs
// 3.2.4 agregar seguros complementarios de salud en companyForm
// 3.2.3 very very small fix
// 3.2.2 cambiar formulario empresa con dropdowns
// 3.2.1 agregar descripcion a pregunta por empresa + agregar logs al form
// 3.2.0 add empresas + form + sacar feedback without request + sacar warning en mapa <-- ACA ESTA EL BUG QUE SE VA A BLANCO
// 3.1.4 use gmapsV2 when it´s available
// 3.1.3 agregar los logs de filtros para que quepan todos
// 3.1.2 Cambiar feedback, agregarle descripcion
// 3.1.1 pedir feedback a la quinta metida
// 3.1.0 cambiar boton de mapa por uno mas grande y otros colores
// 3.0.9 agregar feedback, quitar logo SM en banner
// 3.0.8 Cambiar logo rappicard
// 3.0.7 Quitar logos de bancos en el logo del inicio
// 3.0.6 Sbpay
// 3.0.5 RappiCard
// 3.0.4 Que se abra los descuentos y no el mapa por default siempre. Agregar restobar a los filtros cuando se haga click en el mapa
// 3.0.3 Arreglar link para iphone
// 3.0.2 Update iOS Download app banner
// 3.0.1 Mapa default en iOS app. Banner para ig
// 3.0.0 liberar mapa!
// 2.1.2 add map filter, avoid overlap in maps markers, add query to star card
// 2.1.0 Agregar mapa escondido + nuevos diseños de la barra de busqueda
// 2.0.7 refactor card content, short clubs and loading logs, icons, labels, folders structure,
// intro bottom margin, PR 18.
// 2.0.6 add new filter discountsAmount range
// 2.0.5 swap bg colors in main discount, add discount amount, new filter and sort buttons,
// new search bar
// 2.0.4 swap bg colors in the intro so the bg is grey and buttons are white
// 2.0.3 fix isAppInstalled method
// 2.0.2 add DeviceInfo and change isAppInstalled
// 2.0.1 default days all, ask for location only when they sort for it, remove old code
// 2.0.0 nuevo disno de onboarding y cambio de logo
// 1.9.4 add download app banner for android
// 1.9.3 add homescreen banner for ios
