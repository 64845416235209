export enum CategoriesEnum {
  OTHERS = 'otras',
  RESTOBAR = 'restaurantes / bares / cafes',
  GAS = 'estaciones de servicio',
  LIQUOR = 'licores',
  CINEMA = 'cines',
  PHARMACY = 'farmacias',
  CAR_RENTAL = 'arriendo de autos / transporte',
  COURSES = 'cursos',
  SPORTS = 'deportes',
  HEALTH = 'salud y belleza',
}
