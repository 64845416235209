import React, { useContext, useState } from 'react'

import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded'
import MopedRoundedIcon from '@mui/icons-material/MopedRounded'
import SportsBarRoundedIcon from '@mui/icons-material/SportsBarRounded'
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded'
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded'
import BrunchDiningRoundedIcon from '@mui/icons-material/BrunchDiningRounded'
import CoffeeRoundedIcon from '@mui/icons-material/CoffeeRounded'
import DinnerDiningRoundedIcon from '@mui/icons-material/DinnerDiningRounded'
import RamenDiningRoundedIcon from '@mui/icons-material/RamenDiningRounded'
import LocalPizzaRoundedIcon from '@mui/icons-material/LocalPizzaRounded'
import IcecreamRoundedIcon from '@mui/icons-material/IcecreamRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'
import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded'
import BakeryDiningRoundedIcon from '@mui/icons-material/BakeryDiningRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'

import { AppContext } from '../../../contexts/AppContext'
import { logSMEvent } from '../../../events/logEvent'
import {
  neutral50White,
  neutral80White,
  neutralBlack,
  neutralWhite,
  primary90White,
  primaryPrimary,
  secondary40White,
} from '../../../style'
import {
  addUtmParamsToUrl,
  generateDiscountTitle,
  normalizeDaysToText,
} from '../../../helpers/others'
import { ShareButton } from '../../common/SMButton'
import { Rating } from './map/DiscountMapCard'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { Drawer } from '@mui/material'
import { Discount } from '../../../types/discount'
import { capitalizeFirstChar } from '../../../helpers/normalizeText'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { SMP } from '../../../SMComponents/SMText'
import discountCardStyle from './discountCard.module.css'
import { SMChip } from '../../../SMComponents/SMChip'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { formatPrice } from '../../../helpers/formatPrice'
import { CategoriesEnum } from '../../../types/categories'
import { StarOptions } from '../../admin/components/StarOptions'

const TipoConsumoConditionFrame = ({ tipoConsumo }: any) => {
  const htmlColor = neutral50White
  if (tipoConsumo.includes('presencial') && tipoConsumo.includes('delivery')) {
    return (
      <>
        <div>
          <StoreMallDirectoryRoundedIcon htmlColor={htmlColor} fontSize="small" />
          <MopedRoundedIcon htmlColor={htmlColor} fontSize="small" />
        </div>
        <SMP>Disponible en local y delivery</SMP>
      </>
    )
  }
  if (tipoConsumo === 'presencial') {
    return (
      <>
        <StoreMallDirectoryRoundedIcon htmlColor={htmlColor} fontSize="small" />
        <SMP>Disponible sólo para consumo en local</SMP>
      </>
    )
  }
  if (tipoConsumo === 'delivery') {
    return (
      <>
        <MopedRoundedIcon htmlColor={htmlColor} fontSize="small" />
        <SMP>Disponible sólo para delivery</SMP>
      </>
    )
  }
}

const googleFoodAndDrinkTypesToMUIIcons: {
  [key: string]: React.ReactElement | null
} = {
  american_restaurant: null,
  bar: <SportsBarRoundedIcon />,
  brunch_restaurant: <BrunchDiningRoundedIcon />,
  restaurant: <RestaurantRoundedIcon />,
  food: null,
  point_of_interest: null,
  establishment: null,
  event_venue: null,
  coffee_shop: <CoffeeRoundedIcon />,
  cafe: null,
  store: null,
  italian_restaurant: <DinnerDiningRoundedIcon />,
  japanese_restaurant: <RamenDiningRoundedIcon />,
  pizza_restaurant: <LocalPizzaRoundedIcon />,
  ice_cream_shop: <IcecreamRoundedIcon />,
  fast_food_restaurant: <FastfoodRoundedIcon />,
  indian_restaurant: <SoupKitchenIcon />,
  hamburger_restaurant: <LunchDiningRoundedIcon />,
  sushi_restaurant: <SetMealRoundedIcon />,
  breakfast_restaurant: <BakeryDiningRoundedIcon />,
  vegetarian_restaurant: null,
  vietnamese_restaurant: null,
  chinese_restaurant: null,
  mexican_restaurant: null,
  vegan_restaurant: null,
  bed_and_breakfast: null,
  child_care_agency: null,
  korean_restaurant: null,
  thai_restaurant: null,
}

const { discountHeaderFrame, backgroundImage } = discountCardStyle

export const DiscountStoreInfo = ({ gMapsResults, isStar }: any) => {
  if (!isStar) return <></>
  const allTypes = gMapsResults.map((result: any) => result.types).flat() as string[]
  const allTypesSet = [...new Set(allTypes)]
  return (
    <div>
      <p className="subtitle-2" style={{ margin: '18px 0px' }}>
        Sobre el local:
      </p>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Categorias:</p>
        {allTypesSet.map((type) => {
          if (googleFoodAndDrinkTypesToMUIIcons[type] !== undefined) {
            return (
              <div
                key={type}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                {googleFoodAndDrinkTypesToMUIIcons[type]}
              </div>
            )
          } else {
            return (
              <p
                key={type}
                style={{
                  color: neutral50White,
                }}
              >
                {type},{' '}
              </p>
            )
          }
        })}
      </div>
      {gMapsResults[0]?.regularOpeningHours?.weekdayDescriptions && (
        <>
          <p style={{ marginTop: 10 }}>Horarios: </p>
          {gMapsResults[0].regularOpeningHours.weekdayDescriptions.map((day: string) => {
            return (
              <p
                key={gMapsResults[0].queryID + day}
                style={{
                  color: neutral50White,
                }}
              >
                {day}
              </p>
            )
          })}
        </>
      )}
    </div>
  )
}

export const DISCOUNT_DRAWER_BODY_WIDTH = '85vw'

const GMapsQueryFrame = ({ discount, query }: { discount: Discount; query: string }) => {
  const [showGMapsResults, setShowGMapsResults] = useState(false)
  const queriesGMapsResultsToShow = discount.gMapsResults
    .filter((result: any) => result.query === query) // buscamos solo los resultados de dicha query
    .map((result: any) => {
      return (
        <div
          key={result.queryID + result.formattedAddress}
          style={{ display: 'flex' }}
          onClick={() => {
            logSMEvent('DISCOUNT_DRAWER_GMAPS_LINK_CLICKED', { id: discount.id })
          }}
        >
          <SMP>-</SMP>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px' }}>
            <a
              style={{ color: neutral50White, marginLeft: '8px' }}
              href={result.googleMapsUri}
              target="_blank"
              rel="noreferrer"
            >
              <SMP>{result.formattedAddress || result.formatted_address}</SMP>
            </a>
          </div>
        </div>
      )
    })
  if (queriesGMapsResultsToShow.length === 0) return null
  return (
    <SMHorizontalDiv style={{ alignItems: 'start' }}>
      <LocationOnRoundedIcon fontSize="small" htmlColor={neutral50White} />
      <SMVerticalDiv style={{ gap: 0 }}>
        <SMP>{capitalizeFirstChar(query)}</SMP>
        <div>
          <SMP underline onClick={() => setShowGMapsResults(!showGMapsResults)}>
            {showGMapsResults ? '' : 'Ver resultados de Google Maps'}
          </SMP>
          {showGMapsResults && queriesGMapsResultsToShow}
        </div>
      </SMVerticalDiv>
    </SMHorizontalDiv>
  )
}

export const DiscountDrawer = function ({
  discount,
  isStar,
  handleDiscountClick,
}: {
  discount: Discount | null
  isStar: boolean
  handleDiscountClick: (discount: Discount | null) => void
}) {
  const { auth, filtersSelected, clubs } = useContext(AppContext)
  const { handleOpenReportErrorDrawer } = useContext(DiscountContext)
  const companySelectedIcon = filtersSelected?.Company?.icon
  const handleOnOpenDiscount = () => {
    // ojo que esta condicion tiene que ser la misma que href en la prop del boton
    if (discount) {
      logSMEvent('DISCOUNT_LINK_CLICKED', {
        id: discount.id,
        url: discount.url,
        userRut: auth?.rut || 'none',
        mainTag: discount.mainTag,
        club: discount.club,
        provider: discount?.provider || 'none',
        company: filtersSelected?.Company?.code || 'none',
      })
    }
  }
  if (!discount) return <></>
  return (
    <Drawer
      anchor="right"
      open={discount !== null}
      onClose={() => {
        handleDiscountClick(null)
      }}
      sx={{ zIndex: 200 }}
    >
      <div
        style={{
          maxWidth: DISCOUNT_DRAWER_BODY_WIDTH,
          backgroundColor: 'white',
          overflowY: 'auto',
          // prevent scroll of elements that are behind
        }}
      >
        <div>
          <div
            className={discountHeaderFrame}
            style={{
              height: '140px',
              borderRadius: 0,
            }}
          >
            <img // background para que se vea lindo atras de la imagen
              className={backgroundImage}
              src={discount.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
            <img
              style={{
                height: '140px',
                width: '100%',
                position: 'relative',
                objectFit:
                  discount.club == 'Claro' || discount.club == 'Lider Bci' ? 'contain' : 'cover',
              }}
              src={discount?.bgimage}
              alt="."
              referrerPolicy="no-referrer"
            />
          </div>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderRadius: '10px',
              borderColor: neutral80White,
              backgroundColor: neutralWhite,
              top: '-60px',
              left: '16px',
              width: '90px',
              height: '90px',
            }}
          >
            {discount.club ? (
              <img
                src={clubs[discount.club]?.img}
                style={{ width: clubs[discount.club]?.width }}
                alt="."
              />
            ) : discount.company ? (
              <img src={discount.companyLogo} style={{ width: 32 }} alt="C." />
            ) : discount.provider ? (
              <img src={discount.providerLogo} style={{ width: 32 }} alt="P." />
            ) : null}
          </div>

          {discount.logo && discount.logo !== discount.bgimage ? (
            <div
              style={{
                position: 'relative',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: neutral80White,
                backgroundColor: neutralWhite,
                top: '-152px',
                left: '120px',
                width: '90px',
                height: '90px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                }}
                alt="."
                src={discount.logo}
              />
            </div>
          ) : (
            <div style={{ top: '-152px', left: '120px', width: '90px', height: '90px' }} />
          )}
          {discount.providerLogo && companySelectedIcon ? (
            <div
              style={{
                position: 'relative',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: neutral80White,
                backgroundColor: neutralWhite,
                top: '-242px',
                left: discount.logo && discount.logo !== discount.bgimage ? '226px' : '120px',
                width: '90px',
                height: '90px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: '40px' }} alt="." src={companySelectedIcon} />
            </div>
          ) : (
            <div style={{ top: '-242px', left: '120px', width: '90px', height: '90px' }} />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: '-250px', // tiene que ser lo mismo que marginBottom
            padding: '14px',
            marginBottom: '-250px',
            gap: 14,
          }}
        >
          <h3 style={{ margin: 0 }}>{generateDiscountTitle(discount)}</h3>
          {discount.fecha_hasta && (
            <p
              style={{
                backgroundColor: primary90White,
                padding: '4px 8px',
                borderRadius: '10px',
                color: primaryPrimary,
                width: 'fit-content',
                // fontWeight: 'bold',
              }}
            >
              Hasta el {discount.fecha_hasta}
            </p>
          )}
          <SMHorizontalDiv>
            <SMChip
              onClick={() =>
                handleOpenReportErrorDrawer({
                  source: 'discount_drawer',
                  discount,
                })
              }
            >
              <FlagRoundedIcon sx={{ color: secondary40White }} />
              <p style={{ color: neutralBlack }}>Reportar un error</p>
            </SMChip>
          </SMHorizontalDiv>
          <div>
            {discount?.gMapsResults?.length > 0 && <Rating mapsResult={discount.gMapsResults[0]} />}
          </div>
          <SMVerticalDiv>
            {discount.subTarjeta && (
              <div style={{ display: 'flex' }}>
                <CreditCardRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <p style={{ color: neutral50White, marginLeft: '8px' }}>
                  {capitalizeFirstChar(discount.subTarjeta)
                    .replaceAll('�', 'é')
                    .replaceAll(';', ', ')}
                </p>
              </div>
            )}

            <SMHorizontalDiv>
              <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
              <SMP>{normalizeDaysToText(discount.diasNormalizados)}</SMP>
            </SMHorizontalDiv>
            {discount.topeDescuento && (
              <SMHorizontalDiv>
                <PanToolRoundedIcon fontSize="small" htmlColor={neutral50White} />
                <SMP>Tope del descuento: {formatPrice(discount.topeDescuento)}</SMP>
              </SMHorizontalDiv>
            )}
            {discount.tipoConsumo && discount.mainTag.includes(CategoriesEnum.RESTOBAR) && (
              <SMHorizontalDiv>
                <TipoConsumoConditionFrame tipoConsumo={discount.tipoConsumo} />
              </SMHorizontalDiv>
            )}
            {isStar && <StarOptions discount={discount} />}
            {discount.gMapsResults?.length > 0 && (
              <>
                <div>
                  <SMP>Direcciones:</SMP>
                  <SMP small>Revisar en el sitio oficial antes de ir</SMP>
                </div>
                {discount.gMapsQueries.map((query: string) => {
                  return <GMapsQueryFrame discount={discount} key={query} query={query} />
                })}
              </>
            )}
          </SMVerticalDiv>
          <ShareButton
            discount={discount}
            buttonname="discount_drawer_share_button"
            sx={{
              border: '2px solid',
              borderColor: primaryPrimary,
              color: primaryPrimary,
              backgroundColor: neutralWhite,
              minWidth: '0px',
              maxWidth: 'fit-content',
              minHeight: '0px',
              maxHeight: 'fit-content',
              padding: '12px 28px',
              margin: '20px 0px',
            }}
          >
            <p className="subtitle-1" style={{ marginRight: 5 }}>
              Comparte este descuento
            </p>
            <ShareRoundedIcon />
          </ShareButton>
          {isStar && <SMP>{discount.aiQueryInput}</SMP>}
          <SMVerticalDiv sx={{ gap: 6 }}>
            {capitalizeFirstChar(discount.descripcion)
              // replace any number of one single digit followed by a . for the digit follow by a -
              .replace(/(\d)\./g, '$1-')
              // .split(/(?<!\b(?:dcto|av)|\b\d)\. /i) // no funciona en iOS antiguos
              .split(/(?:dcto|av)?\. /i) // si funciona
              // .split(/(?<![dD]cto)(?<![aA]v)\. /) // no funciona en iOS antiguos. Split on dot not preceded by "dcto" and has a space after it no funciona
              .filter((sentence) => sentence.trim().length > 0) // Filter out empty sentences
              .map((sentence, index) => {
                return (
                  <SMP black key={index}>
                    {'• ' + capitalizeFirstChar(sentence) + '.'}
                  </SMP>
                )
              })}
          </SMVerticalDiv>
          {discount.gMapsResults && (
            <DiscountStoreInfo gMapsResults={discount.gMapsResults} isStar={isStar} />
          )}
          {discount.condiciones && (
            <>
              <SMP bold black className="subtitle-2" style={{ margin: '18px 0px' }}>
                Terminos y condiciones
              </SMP>
              <SMP>{discount.condiciones}</SMP>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <VisibilityRoundedIcon />
                <p className="body-1" style={{ marginLeft: '4px' }}>
                  {totalViews || '...'}
                </p>
              </div>
              <p className="body-2" style={{ color: neutral50White }}>
                Visualizaciones
              </p>
            </div> */}
            <div />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxHeight: '24px',
                  maxWidth: '24px',
                  backgroundColor: neutralWhite,
                  borderRadius: '50%',
                  padding: '8px',
                  marginRight: '6px',
                }}
              >
                <img src={clubs[discount.club]?.img} style={{ width: 45 }} alt="." />
              </div>
              <SMPrimaryButton
                sx={{ height: 55, gap: 10, marginTop: 20 }}
                onClick={handleOnOpenDiscount}
                href={addUtmParamsToUrl(discount)}
              >
                <SMP white bold>
                  Ir al descuento
                </SMP>
                <OpenInNewRoundedIcon fontSize="small" htmlColor={neutralWhite} />
              </SMPrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
