import { useContext, useEffect, useState } from 'react'

import '../../../App.css'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, primary20White } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { optionLabel } from '../../../consts/filterOptions'
import { categoriesIcons } from '../../common/Icons'

const filterLabel = 'MainTag'

export const CategoryCheckBox = function ({ category }) {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(category))
  }, [filtersSelected, category])

  const contentColor = checked ? neutralWhite : primary20White

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        handleFilterSelection(filterLabel, category, filtersSelected, setFiltersSelected)
      }}
      sx={{
        minWidth: '136px',
        maxWidth: '200px',
        minHeight: '136px',
        maxHeight: '200px',
        borderRadius: '20px',
        flexDirection: 'column',
        padding: '22px',
      }}
    >
      {categoriesIcons({ fontSize: 40, color: contentColor, marginBottom: 5 })[category]}
      <p className="subtitle-3" style={{ color: contentColor }}>
        {optionLabel[category] || category.capitalize()}
      </p>
    </ICheckBox>
  )
}
