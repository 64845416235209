import { useEffect, useState } from 'react'
import { request } from '../../helpers/request'

import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { visuallyHidden } from '@mui/utils'
import {
  optionsCajaCompensacion,
  optionsMutual,
  optionsRRHH,
  optionsSeguros,
} from '../../consts/providers'
import TextField from '@mui/material/TextField'
import { Alert, Button } from '@mui/material'
import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const headCells = [
  {
    id: 'company',
    label: 'Nombre empresa',
  },
  {
    id: 'code',
    label: 'Code',
  },
  {
    id: 'logo',
    label: 'Logo',
  },
  {
    id: 'proveedores',
    label: 'Proveedores',
  },
  {
    id: 'form_info',
    label: 'Form info',
  },
  {
    id: 'date',
    label: 'Fecha',
  },
  {
    id: 'subir',
    label: 'Subir',
  },
  {
    id: 'eliminar',
    label: 'Eliminar',
  },
  {
    id: 'subido',
    label: 'Subido',
  },
]

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.formId} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
}

const CompanyRow = ({ row, setShowAlert, password, deletedList, setDeletedList, companies }) => {
  const [companyLabel, setCompanyLabel] = useState(row.company)
  const [companyLogo, setCompanyLogo] = useState('')
  const [companyCode, setCompanyCode] = useState(row.company.toUpperCase().replace(/\s/g, ''))
  useEffect(() => {
    setCompanyLogo('')
    setCompanyCode(row.company.toUpperCase().replace(/\s/g, ''))
    setCompanyLabel(row.company)
  }, [row.company])

  const cajaCompensacion = optionsCajaCompensacion.find((c) => c.code === row.cajaCompensacion)
  const mutual = optionsMutual.find((m) => m.code === row.mutual)
  const rrhh = optionsRRHH.find((r) => r.code === row.rrhh)
  const seguro = optionsSeguros.find((s) => s.code === row.seguro)

  return (
    <TableRow
      hover
      onClick={() => {
        // alert('click')
      }}
      role="checkbox"
      tabIndex={-1}
      key={row.formId}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell align="left">
        <TextField
          onChange={(e) => setCompanyLabel(e.target.value)}
          variant="standard"
          value={companyLabel}
        />
      </TableCell>
      <TableCell align="left">
        {!row.subido ? (
          <>
            <TextField
              onChange={(e) => setCompanyCode(e.target.value)}
              variant="standard"
              value={companyCode}
              style={{ borderColor: 'red' }}
            />
            {companies.includes(companyCode) && <DownloadDoneRoundedIcon />}
          </>
        ) : (
          <p>
            {companyCode.slice(0, 20)}
            {companyCode.length > 20 && '...'}
          </p>
        )}
      </TableCell>
      <TableCell align="left">
        {!row.subido && (
          <TextField
            onChange={(e) => setCompanyLogo(e.target.value)}
            label="logo"
            variant="standard"
          />
        )}
        <img loading="lazy" width="26" src={companyLogo} alt="" />
      </TableCell>
      <TableCell align="left">
        <img
          loading="lazy"
          width="22"
          style={{ marginRight: 0 }}
          src={cajaCompensacion?.icon}
          alt={cajaCompensacion?.label}
        />
        <img loading="lazy" width="22" style={{ marginRight: 0 }} src={mutual?.icon} alt="" />
        <img loading="lazy" width="22" style={{ marginRight: 0 }} src={seguro?.icon} alt="" />
        <img loading="lazy" width="22" style={{ marginRight: 0 }} src={rrhh?.icon} alt="" />
      </TableCell>
      <TableCell>
        <p>
          {row.form_v?.includes('add') && JSON.stringify(row).replace(',', ', ')}
          {row.rrhhName ? `${row.rrhhName} ` : ''}
          {row.rrhhEmail ? `${row.rrhhEmail} ` : ''}
          {row.userName ? `SM ${row.userName} (${companyLabel}) ` : ''}
          {row.userPhone
            ? row.userPhone.length === 9 && row.userPhone[0] === '9'
              ? `+56${row.userPhone} `
              : `${row.userPhone} `
            : ''}
        </p>
      </TableCell>
      <TableCell align="left">{new Date(row.date).toLocaleDateString()}</TableCell>
      <TableCell align="left">
        {row.subido ? (
          'Subido'
        ) : row.form_v?.includes('add') ? (
          row.form_v
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              const body = JSON.stringify({
                code: companyCode,
                icon: companyLogo,
                label: companyLabel,
                formId: row.formId,
                password,
                providers: [row.cajaCompensacion, row.mutual, row.seguro, row.rrhh].filter(
                  (x) => x && !x.includes('Otro')
                ),
              })
              request('upload_company', {
                method: 'POST',
                body,
              }).then((r) => {
                if (r.status === 'success') {
                  setShowAlert('Empresa subida correctamente: ' + companyLabel)
                  setDeletedList([...deletedList, row.formId])
                } else {
                  alert('Error al subir: ' + r.message)
                }
              })
              // show alert that it was uploaded
            }}
          >
            Subir
          </Button>
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color={row.subido ? 'error' : 'warning'}
          onClick={() => {
            const body = JSON.stringify({
              formId: row.formId,
              password,
            })
            request('delete_company_form', {
              method: 'POST',
              body,
            }).then((r) => {
              if (r.status === 'success') {
                setShowAlert('Solicitud fue eliminada exitosamente')
                if (row.formId) {
                  setDeletedList([...deletedList, row.formId])
                }
              } else {
                alert('Error al eliminar: ' + r.message)
              }
            })
          }}
        >
          {row.subido ? 'DEL ?' : 'ELIMINAR'}
        </Button>
      </TableCell>
      <TableCell align="left">{row.subido ? <DownloadDoneRoundedIcon /> : 'No'}</TableCell>
    </TableRow>
  )
}

export const CompaniesRequest = () => {
  const [searchText, setSearchText] = useState('')
  const [password, setPassword] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [companyForms, setCompanyForms] = useState([])
  const [showOnlyToUpload, setShowOnlyToUpload] = useState(false)
  const [showOnlyWithoutData, setShowOnlyWithoutData] = useState(false)
  const [showOnlyAlreadyUploaded, setShowOnlyAlreadyUploaded] = useState(false)
  const [showOnlyNotYetUploaded, setShowOnlyNotYetUploaded] = useState(false)
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    request('companies').then((r) => {
      // r.data is a list of object with the field date {_nanoseconds: 671000000, _seconds:1703941335}
      setCompanies(r.data?.map((company) => company.code))
    })
  }, [])

  useEffect(() => {
    request('company_form').then((r) => {
      // r.data is a list of object with the field date {_nanoseconds: 671000000, _seconds:1703941335}
      setCompanyForms(r.data?.map((form) => ({ ...form, date: form.date._seconds * 1000 })))
    })
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companyForms.length) : 0

  const [visibleRows, setVisibleRows] = useState([])
  const [deletedList, setDeletedList] = useState([])

  useEffect(() => {
    setVisibleRows(
      companyForms
        .filter((x) => x.company?.toLowerCase().includes(searchText?.toLowerCase()))
        .filter((x) => (showOnlyToUpload ? !x.subido : true))
        .filter((x) =>
          showOnlyWithoutData ? !x.cajaCompensacion && !x.mutual && !x.seguro && !x.rrhh : true
        )
        .filter((x) =>
          showOnlyAlreadyUploaded
            ? companies.includes(x.company.toUpperCase().replace(/\s/g, ''))
            : true
        )
        .filter((x) =>
          showOnlyNotYetUploaded
            ? !companies.includes(x.company.toUpperCase().replace(/\s/g, ''))
            : true
        )
        .filter((x) => !deletedList.includes(x.formId))
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    )
  }, [
    companyForms,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchText,
    deletedList,
    showOnlyToUpload,
    showOnlyWithoutData,
    showOnlyAlreadyUploaded,
    showOnlyNotYetUploaded,
  ])

  return (
    <Box sx={{ width: '100%' }}>
      {showAlert && (
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          {showAlert}
        </Alert>
      )}
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Toolbar>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            Empresas Solicitadas ({companyForms.length})
          </Typography>
        </Toolbar>
        <Typography sx={{ flex: '1 1 100%', ml: 3, mb: 3 }} variant="h7" component="div">
          Empresas subidas: {companyForms.filter((x) => x.subido).length} &nbsp;|&nbsp; Empresas por
          subir: {companyForms.filter((x) => !x.subido).length} &nbsp;|&nbsp; Empresas solicitadas
          hoy:{' '}
          {
            companyForms.filter(
              (x) => new Date(x.date).toLocaleDateString() === new Date().toLocaleDateString()
            ).length
          }
        </Typography>
        Key:
        <TextField
          type="password"
          sx={{ ml: 3 }}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          variant="standard"
        />
        Nombre empresa a buscar:
        <TextField
          sx={{ ml: 3 }}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          variant="standard"
        />
        Por subir
        <Switch
          checked={showOnlyToUpload}
          onChange={() => setShowOnlyToUpload(!showOnlyToUpload)}
        />
        Sin datos
        <Switch
          checked={showOnlyWithoutData}
          onChange={() => setShowOnlyWithoutData(!showOnlyWithoutData)}
        />
        Code ya subido
        <Switch
          checked={showOnlyAlreadyUploaded}
          onChange={() => setShowOnlyAlreadyUploaded(!showOnlyAlreadyUploaded)}
        />
        Code NO subido
        <Switch
          checked={showOnlyNotYetUploaded}
          onChange={() => setShowOnlyNotYetUploaded(!showOnlyNotYetUploaded)}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => (
                <CompanyRow
                  key={index}
                  row={row}
                  setShowAlert={setShowAlert}
                  password={password}
                  deletedList={deletedList}
                  setDeletedList={setDeletedList}
                  companies={companies}
                />
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={companyForms.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  )
}
