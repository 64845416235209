import React, { useContext, useEffect, useRef, useState } from 'react'
import Divider from '@mui/material/Divider'

import { DiscountScreen } from '../discounts/components/DiscountScreen'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { request } from '../../helpers/request'
import { AppContext, UserRole } from '../../contexts/AppContext'
import {
  SMContent,
  SMHorizontalDiv,
  SMVerticalDiv,
  SMView,
  SMWebViewContent,
} from '../../SMComponents/SMView'
import { SMPrimaryButton } from '../../SMComponents/SMButton'
import { SMAppBar } from '../../SMComponents/SMAppBar'
import { Discount } from '../../types/discount'
import { UnAuthorizedView } from './components/UnAuthorizedView'
import { defaultDiscount } from '../../helpers/deafultDiscount'
import { CreateOrEditDiscountForm } from './components/CreateOrEditDiscountForm'
import { SMH2, SMP } from '../../SMComponents/SMText'
import { SMCard } from '../../SMComponents/SMCard'
import { Company } from '../../types/company'
import { Skeleton } from '@mui/material'

import { allProviders } from '../../consts/providers'

export const AdminCompany = () => {
  const { auth } = useContext(AppContext)
  const [providerIsExpanded, setProviderIsExpanded] = useState(false)
  const [openCreateDiscount, setOpenCreateDiscount] = useState(false)
  const [companiesInfoExpanded, setCompaniesInfoExpanded] = useState(false)
  const [companyIsExpanded, setCompanyIsExpanded] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [companiesPublishedDiscount, setCompaniesPublishedDiscounts] = useState<Discount[]>([])
  const [providersDiscount, setProvidersDiscounts] = useState<Discount[]>([])
  const editRef = useRef(null)
  const [company, setCompany] = useState<Company | null>(null)

  const [discountToCreate, setDiscountToCreate] = useState<Discount>(defaultDiscount)

  let INITIAL_DISCOUNT_STATE = { ...defaultDiscount, company: auth?.entityId || '' }
  useEffect(() => {
    if (!auth) return
    request(`company_discounts?company=${auth.entityId}`, {}).then((response) => {
      if (response.status === 'success') {
        setCompaniesPublishedDiscounts(response.data)
      }
    })
    request('companies').then((r) => {
      setCompany(
        (r.data?.filter((company: Company) => company.code === auth?.entityId)[0] as Company) ||
          null
      )
    })
    INITIAL_DISCOUNT_STATE = { ...defaultDiscount, company: auth?.entityId || '' }
    setDiscountToCreate(INITIAL_DISCOUNT_STATE)
  }, [auth])

  useEffect(() => {
    if (company) {
      request(`provider_discounts?providers=${company.providers.join(',')}`, {}).then(
        (response) => {
          if (response.status === 'success') {
            setProvidersDiscounts(response.data)
            console.log('response.data', response.data)
          }
        }
      )
    }
  }, [company])

  if (auth?.role !== UserRole.COMPANY && auth?.role !== UserRole.ADMIN) {
    return <UnAuthorizedView />
  }
  const handleOnEdit = (discountToEdit: Discount) => {
    setIsEditing(true)
    setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE, ...discountToEdit })
    // scroll to Edit section
    const editCurrentRef = editRef.current as HTMLElement | null
    if (editCurrentRef) editCurrentRef.scrollIntoView({ behavior: 'smooth' })
  }

  const handleOnDelete = (discountToDelete: Discount) => {
    setCompaniesPublishedDiscounts(
      companiesPublishedDiscount.filter((d) => d.id !== discountToDelete.id)
    )
    setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE })
    request(
      'admin/delete_discount',
      {
        method: 'POST',
        body: JSON.stringify({
          discountId: discountToDelete.id,
        }),
      },
      auth
    )
  }

  return (
    <SMView>
      <SMAppBar />
      <SMWebViewContent>
        <SMCard>
          <SMContent>
            <SMHorizontalDiv maxSpaceBetween>
              <SMHorizontalDiv>
                <img
                  loading="lazy"
                  width="40"
                  style={{ marginRight: 14, minWidth: '40px', maxWidth: '40px', maxHeight: '40px' }}
                  src={company?.icon}
                  alt=""
                />
                <SMH2>{company?.label || 'Cargando...'}</SMH2>
              </SMHorizontalDiv>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCompaniesInfoExpanded(!companiesInfoExpanded)
                }}
              >
                {companiesInfoExpanded ? (
                  <ExpandLessRoundedIcon fontSize="large" />
                ) : (
                  <ExpandMoreRoundedIcon fontSize="large" />
                )}
              </div>
            </SMHorizontalDiv>
            <SMP class="subtitle-1">Cantidad de convenios: {company?.providers?.length}</SMP>
            {companiesInfoExpanded && (
              <>
                <Divider />
                <SMH2 class="subtitle-1">Tus convenios:</SMH2>
                {!company?.providers && (
                  <Skeleton variant="text" sx={{ fontSize: '2rem', width: '100%' }} />
                )}
                {company?.providers.map((provider) => {
                  const providerData = allProviders.find((p) => p.code === provider)
                  return (
                    <SMHorizontalDiv key={provider}>
                      <img
                        loading="lazy"
                        width="30"
                        style={{ marginRight: 0 }}
                        src={providerData?.icon}
                        alt={providerData?.label}
                      />
                      <SMP>{providerData?.label}</SMP>
                    </SMHorizontalDiv>
                  )
                })}
                <SMP>
                  Si requiere realizar alguna modificación, por favor, póngase en contacto con el
                  administrador de su cuenta al siguiente número: +569 7476 9848.
                </SMP>
              </>
            )}
          </SMContent>
        </SMCard>
        <SMCard>
          <SMContent>
            <SMHorizontalDiv maxSpaceBetween>
              <SMH2>Beneficios de terceros:</SMH2>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setProviderIsExpanded(!providerIsExpanded)
                }}
              >
                {providerIsExpanded ? (
                  <ExpandLessRoundedIcon fontSize="large" />
                ) : (
                  <ExpandMoreRoundedIcon fontSize="large" />
                )}
              </div>
            </SMHorizontalDiv>
            <SMP class="subtitle-1">Total: {providersDiscount.length} beneficios</SMP>
            {providerIsExpanded && (
              <>
                <Divider />
                <div style={{ marginTop: -100 }}>
                  <DiscountScreen
                    isLoadingDiscounts={false}
                    discountsToShow={providersDiscount}
                    discounts={providersDiscount}
                    setNavButtonClicked={() => null}
                    search=""
                    handleChangeSearch={() => null}
                    isStar={false}
                    userLevel={2}
                  />
                </div>
              </>
            )}
          </SMContent>
        </SMCard>
        <SMCard>
          <SMContent>
            <SMHorizontalDiv maxSpaceBetween>
              <SMH2>Beneficios propios de {company?.label}:</SMH2>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCompanyIsExpanded(!companyIsExpanded)
                }}
              >
                {companyIsExpanded ? (
                  <ExpandLessRoundedIcon fontSize="large" />
                ) : (
                  <ExpandMoreRoundedIcon fontSize="large" />
                )}
              </div>
            </SMHorizontalDiv>
            <SMP class="subtitle-1">Total: {companiesPublishedDiscount.length} beneficios</SMP>
            {companyIsExpanded && (
              <>
                <Divider />
                <div style={{ marginTop: -100 }}>
                  <DiscountScreen
                    isLoadingDiscounts={false}
                    discountsToShow={companiesPublishedDiscount}
                    discounts={companiesPublishedDiscount}
                    setNavButtonClicked={() => null}
                    search=""
                    handleChangeSearch={() => null}
                    isStar={false}
                    userLevel={2}
                    onEdit={handleOnEdit}
                    onDelete={handleOnDelete}
                  />
                </div>
              </>
            )}
          </SMContent>
        </SMCard>
        <SMCard>
          <SMContent>
            <SMHorizontalDiv maxSpaceBetween>
              <SMH2>Portal de creación y edición</SMH2>
              {isEditing ? (
                <SMVerticalDiv>
                  <SMP smref={editRef}>Editando beneficio: {discountToCreate?.id}</SMP>
                  <SMPrimaryButton
                    maxWidth="20px"
                    onClick={() => {
                      setIsEditing(false)
                      setDiscountToCreate({ ...INITIAL_DISCOUNT_STATE })
                      setOpenCreateDiscount(true)
                    }}
                  >
                    Cambiar a crear beneficio
                  </SMPrimaryButton>
                </SMVerticalDiv>
              ) : openCreateDiscount ? (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenCreateDiscount(false)
                  }}
                >
                  <ExpandLessRoundedIcon fontSize="large" />
                </div>
              ) : (
                <SMPrimaryButton
                  maxWidth="20px"
                  onClick={() => setOpenCreateDiscount(!openCreateDiscount)}
                >
                  Crear nuevo beneficio
                </SMPrimaryButton>
              )}
            </SMHorizontalDiv>
            {(openCreateDiscount || isEditing) && (
              <>
                <Divider />
                <SMHorizontalDiv sx={{ alignItems: 'start' }}>
                  <CreateOrEditDiscountForm
                    discountToCreate={discountToCreate}
                    setDiscountToCreate={setDiscountToCreate}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    publishedDiscounts={companiesPublishedDiscount}
                    setPublishedDiscounts={setCompaniesPublishedDiscounts}
                  />
                  <Divider orientation="vertical" flexItem />
                  <SMVerticalDiv>
                    <SMP class="subtitle-1">
                      A continuación, se presenta la visualización del descuento que se está
                      {isEditing ? 'editando' : 'cerando'} (se invita a interactuar con la tarjeta
                      mediante un clic sobre la misma):
                    </SMP>
                    <div style={{ marginTop: -100 }}>
                      <DiscountScreen
                        isLoadingDiscounts={false}
                        discountsToShow={[discountToCreate]}
                        discounts={[discountToCreate]}
                        setNavButtonClicked={() => null}
                        search=""
                        handleChangeSearch={() => null}
                        isStar={false}
                        userLevel={2}
                      />
                    </div>
                  </SMVerticalDiv>
                </SMHorizontalDiv>
              </>
            )}
          </SMContent>
        </SMCard>
      </SMWebViewContent>
    </SMView>
  )
}
