export const generateDiscountTitle = (discount) => {
  if (!discount) return ''
  const { titulo } = discount
  if (!titulo) return ''
  return titulo?.capitalize()
}

export const getUrlParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param) || ''
}

export const setUrlParam = (param, value) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(param, value || '')
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
}

export const normalizeDaysToText = (normalizeDays) => {
  if (!normalizeDays) return 'Todos los días'
  normalizeDays = normalizeDays.replaceAll('NOT FOUND', '')
  if (!normalizeDays) return 'Todos los días'
  if (normalizeDays.indexOf(',') === -1) {
    return normalizeDays
      .replaceAll('L', 'Lunes')
      .replaceAll('M', 'Martes')
      .replaceAll('W', 'Miércoles')
      .replaceAll('J', 'Jueves')
      .replaceAll('V', 'Viernes')
      .replaceAll('S', 'Sábado')
      .replaceAll('D', 'Domingo')
  }
  if (normalizeDays === 'L,M,W,J,V,S,D') return 'Todos los días'
  return normalizeDays
    .replaceAll('L', 'Lun')
    .replaceAll('M', 'Mar')
    .replaceAll('W', 'Mie')
    .replaceAll('J', 'Jue')
    .replaceAll('V', 'Vie')
    .replaceAll('S', 'Sab')
    .replaceAll('D', 'Dom')
    .replaceAll(',', ', ')
}

export const addUtmParamsToUrl = (item) => {
  try {
    let basedUrl = item.url
    // Check if URL starts with http or https
    if (!/^https?:\/\//i.test(basedUrl)) {
      // If not, prepend 'http://'
      basedUrl = 'http://' + basedUrl
    }
    const utmParams = {
      utm_source: 'savemoney',
      utm_medium: 'referral',
      // utm_campaign: item.id,
      utm_term: item.id,
    }
    const url = new URL(basedUrl)
    Object.keys(utmParams).forEach((key) => url.searchParams.append(key, utmParams[key]))
    return url.toString()
  } catch (error) {
    return ''
  }
}
